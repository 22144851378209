// src/components/NewLeads.tsx
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import Listing from '../components/Listing';
import Chats from '../components/Chats';
import Summary from '../components/Summary';

import './NewLeads.css';

interface Property {
  id: number;
  address: string;
  price: number;
  bedrooms: number;
  bathrooms: number;
  imageUrl: string;
}

interface API {
  api: string;
}

const ManualReview: React.FC<API> = ({api}) => {
  const { user } = useContext(AuthContext);
  const [clients, setClients] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const API:string = user ? `${api}/get_flagged_people?client_id=${user.id}`: '';

  function handleSelectedClient(client: string) {
    setSelectedClient(client);
  }

  // Fetch properties from the API when the component mounts
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(API);
        setClients(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching clients:', err);
        setError('Failed to load clients.');
        setLoading(false);
      }
    };

    fetchClients();
  }, [API]);


  return (
    <div className="responsive-flex">
      {/* Client List */}
      <Listing clients={clients} loading={loading} error={error} selectedClient={selectedClient} handleSelectedClient={handleSelectedClient} />
      {/* Chat History */}
      {user && <Chats api={api} selectedClient={selectedClient} clientID={user.id}/>}
      {/* Summary */}
      {user && <Summary api={api} selectedClient={selectedClient} clientID={user.id}/>}
    </div>
  );
};

export default ManualReview;
