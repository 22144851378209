// src/contexts/AuthContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

interface AuthContextType {
  isLoggedIn: boolean | null; // Updated type
  user: User | null;
  login: () => void;
  logout: () => Promise<void>;
}

interface User {
  id: string;
  email: string;
  name: string;
  picture: string;
}

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: null, // Updated initial value
  user: null,
  login: () => {},
  logout: async () => {},
});

interface Props {
  children: ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null); // Updated initial state
  const [user, setUser] = useState<User | null>(null);
  const api = process.env.REACT_APP_FLASKSERVER || '';
  if (!api) {
    console.error('FLASKSERVER environment variable is not set');
  }    

  // Function to handle login by redirecting to backend
  const login = () => {
    // Redirect the user to the backend's /auth/google endpoint
    window.location.href = `${api}/auth/google`;
  };

  // Function to handle logout
  const logout = async () => {
    try {
      await axios.post(
        `${api}/logout`,
        {},
        { withCredentials: true }
      );
      setIsLoggedIn(false);
      setUser(null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Function to check if the user is already logged in
  const checkLoginStatus = async () => {
    try {
      const response = await axios.get(
        `${api}/check_login`,
        { withCredentials: true }
      );
      if (response.status === 200 && response.data.isLoggedIn) {
        setIsLoggedIn(true);
        setUser(response.data.user);
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
    } catch (error) {
      console.error('Error checking login status:', error);
      setIsLoggedIn(false);
      setUser(null);
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
