import React from 'react';
import './Footer.css';
import { motion } from 'framer-motion';

const footerVariants = {
  hidden: { y: 100 },
  visible: { y: 0 },
};

const Footer: React.FC = () => {
  return (
    <div
      className="footer"
      // initial="hidden"
      // animate="visible"
      // variants={footerVariants}
      // transition={{ type: 'spring', stiffness: 75, duration: 0.5, delay: 0.5 }}
    >
      <p>© 2024 Kindred PM. All rights reserved.</p>
      <p>Contact: phillip@kindredpm.ai</p>
      {/* 관련 링크 추가 가능 */}
    </div>
  );
};

export default Footer;
