// src/components/Navbar.tsx
import React, { useState, useContext, useEffect, useMemo } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { UnReadCountContext } from '../contexts/UnReadCountContext';
import {
  ChatBubbleBottomCenterTextIcon,
  UsersIcon,
  FolderIcon,
  FlagIcon,
  ArrowUpTrayIcon,
  CommandLineIcon,
  PencilSquareIcon,
  HomeIcon,
  CogIcon,
  ChartBarIcon,
  ClipboardDocumentListIcon,
  ArrowLeftStartOnRectangleIcon
} from '@heroicons/react/24/outline';
import logo from '../assets/logo_basic_white_fit.png';
import './Navbar.css';


const Navbar: React.FC = () => {
  const ADMIN_ID: string = useMemo(() => process.env.REACT_APP_ADMIN_ID || '', []);
  const { user, logout, isAdmin } = useContext(AuthContext);
  const { isTrigger, setIsTrigger, isReadCount, setUnReadCount, isReadCountFlag, setUnReadCountFlag, isReadCountMaint, setUnReadMaintCount, isReadCountMaintFlag, setUnReadCountMaintFlag } = useContext(UnReadCountContext);
  const [isClientExist, setIsClientExist] = useState<boolean>(false);
  const [loadingInbox, setLoadingInbox] = useState<boolean>(false);
  const [loadingFlagged, setLoadingFlagged] = useState<boolean>(false);
  const [errorInbox, setErrorInbox] = useState<string | null>(null);
  const [errorFlagged, setErrorFlagged] = useState<string | null>(null);

  const api: string = useMemo(() => process.env.REACT_APP_FLASKSERVER || '', []);

  useEffect(() => {
    if (!user) return;
    if (!api) return;

    const inboxEndpoint = `${api}/count_unread?client_id=${user.id}`;

    const fetchInbox = async () => {
      setLoadingInbox(true);
      setErrorInbox(null);
      try {
        const response = await axios.get(inboxEndpoint);
        if (typeof response.data.count.crm === 'number') {
          setUnReadCount(response.data.count.crm);
        }
        if (typeof response.data.count.crm_flag === 'number') {
          setUnReadCountFlag(response.data.count.crm_flag);
        }
        if (typeof response.data.count.WO === 'number') {
          setUnReadMaintCount(response.data.count.WO);
        }
        if (typeof response.data.count.WO_flag === 'number') {
          setUnReadCountMaintFlag(response.data.count.WO_flag);
        }
      } catch (err) {
        console.error('Error fetching inbox count:', err);
        setErrorInbox('Failed to load inbox count.');
      } finally {
        setLoadingInbox(false);
        setIsTrigger(false);
      }
    };
  
    fetchInbox();
  }
  , [api, user, isTrigger]);


  // useEffect(() => {
  //   if (!user) return;
  //   if (!api) return;

  //   const flaggedEndpoint = `${api}/count_unread?client_id=${user.id}`;

  //   const fetchFlagged = async () => {
  //     setLoadingFlagged(true);
  //     setErrorFlagged(null);
  //     try {
  //       const response = await axios.get(flaggedEndpoint);
  //       if (typeof response.data.data === 'number') {
  //         setUnReadCountFlag(response.data.data);
  //       } else {
  //         setErrorFlagged('Invalid data format for flagged count.');
  //       }
  //     } catch (err) {
  //       console.error('Error fetching flagged count:', err);
  //       setErrorFlagged('Failed to load flagged count.');
  //     } finally {
  //       setLoadingFlagged(false);
  //     }
  //   };
  
  //   fetchFlagged();
  // }
  // , [api, user]);

  const handleLogout = async () => {
    await logout();
  };

  const navAgents = [
    {
      role: "Leasing",
      subRole: [
        { name: 'New Leads', path: '/new-leads', icon: ChatBubbleBottomCenterTextIcon },
        { name: 'Properties', path: '/properties', icon: HomeIcon },
        { name: 'Manual Review', path: '/manual-review', icon: FlagIcon },
        { name: 'Upload Documents', path: '/upload-documents', icon: ArrowUpTrayIcon },
        { name: 'Prompts', path: '/prompts', icon: CommandLineIcon },
        { name: 'Dashboard', path: '/dashboard', icon: ChartBarIcon },
        { name: 'Register', path: '/register', icon: PencilSquareIcon },
        { name: 'Subscriptors', path: '/subscriptors', icon: PencilSquareIcon },
      ]
    },
    {
      role: "Maintenance",
      subRole: [
        { name: 'Work Orders', path: '/work-orders', icon: ChatBubbleBottomCenterTextIcon },
        { name: 'Vendors', path: '/vendors', icon: UsersIcon },
        { name: 'Categories', path: '/Maintenance Categories', icon: FolderIcon },
        { name: 'Manual Review', path: '/work-orders-flag', icon: FlagIcon },
        { name: 'Repair Tickets', path: '/repair-tickets', icon: FolderIcon },
        { name: 'Properties & Tenants', path: '/propertiesAndTenants', icon: ClipboardDocumentListIcon },
        // { name: 'Settings', path: '/settings', icon: CogIcon },
        { name: 'Logout', path: '/logout', icon: ArrowLeftStartOnRectangleIcon }
      ]
    }
  ];

  

  return (
    <div className="navbar-container">
      {/* Neumorphic Logo Section */}
      <NavLink className="logo-container" to="/login">
        <img src={logo} alt="KindredPM Logo" className="logo-image" />
      </NavLink>

      {/* Navigation Links */}
      <nav className="nav-links">
        {navAgents.map((agent) => (
          <div key={agent.role}> {/* key 추가 */}
            <div className='role-item'>
              <h3>{agent.role}</h3>
            </div>
            {agent.subRole.map((item) => {
              if (!isAdmin && item.name === 'Register') {
                return null;
              }
              if (!isAdmin && item.name === 'Subscriptors') {
                return null;
              }
              return (
                <NavLink
                  key={item.name}
                  to={item.path}
                  className={({ isActive }) =>
                    `nav-item ${isActive ? 'active' : ''}`
                  }
                  onClick={item.name === 'Logout' ? handleLogout : undefined} // Logout 클릭 시 handleLogout 호출
                >
                  {/* Icon */}
                  <item.icon className="nav-icon" />
                  <span className="nav-text">{item.name}</span>
                  {item.name === 'New Leads' && <div className="nav-isRead">{isReadCount}</div>}
                  {item.name === 'Manual Review' && <div className="nav-isRead">{isReadCountFlag}</div>}
                  {item.name === 'Work Orders' && <div className="nav-isRead">{isReadCountMaint}</div>}
                  {item.name === 'Maint Review' && <div className="nav-isRead">{isReadCountMaintFlag}</div>}
                </NavLink>
              );
            })}
          </div>
        ))}
      </nav>
      <div>
        <LocalTime />
      </div>
    </div>
  );
};

const LocalTime: React.FC = () => {
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);
  return <div className="local-time">{time.toLocaleTimeString()}</div>;
};

export default Navbar;
