// src/components/Properties.tsx
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import './Properties.css'; // Properties.css 임포트
import Listing from '../components/Listing';
import PropertyDetail from '../components/PropertyDetail';

interface Property {
  [key: string]: string;
}

interface API {
  api: string;
}

const Properties: React.FC<API> = ({ api }) => {
  const { user } = useContext(AuthContext);

  const [properties, setProperties] = useState<Property>();
  const [selectedProperty, setSelectedProperty] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [APIEndpoint, setAPIEndpoint] = useState<string>(user ? `${api}/getproperties?client_id=${user.id}` : '');
  const [asd, setAsd] = useState<string>(user ? `${api}/getproperties?client_id=${user.id}` : '');

  function handleSelectedProperty(property: string) {
    setSelectedProperty(property);
  }

  // Fetch properties from the API when the component mounts
  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(APIEndpoint);
        setProperties(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching clients:', err);
        setError('Failed to load clients.');
        setLoading(false);
      }
    };

    if (APIEndpoint) {
      fetchProperties();
    }
  }, [APIEndpoint]);

  return (
    <div className="content-area">
      {/* Client List */}
      {properties ? (
        <Listing
          clients={Object.keys(properties)}
          loading={loading}
          error={error}
          selectedClient={selectedProperty}
          handleSelectedClient={handleSelectedProperty}
        />
      ) : (
        ''
      )}
      {/* Properties */}
      {properties && user && selectedProperty ? (
        <div className="content-container">
          <div className="content-header" style={{ cursor: 'pointer' }}>
            <h2 className="content-title">Property</h2>
            <div className="property-section">
              <PropertyDetail api={api} dir="get_property_details" client_id={user.id} Kindred_ID={properties[selectedProperty]} />
              <PropertyDetail api={api} dir="get_specific_policies" client_id={user.id} Kindred_ID={properties[selectedProperty]} />
              {/* <PropertyDetail api={api} dir="qualify_tenants" client_id={user.id} Kindred_ID={properties[selectedProperty]} /> */}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Properties;
