// src/components/Listing.tsx
import React, { useState, useMemo, useContext } from 'react';
import axios from 'axios';
import { ChevronDownIcon, ChevronUpIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { AuthContext } from "../contexts/AuthContext";
import './Listing.css'; // Neumorphic 스타일용 CSS 파일

interface Clients {
  email: string;
  client_id: string;
  folder_name: string;
}

interface ListingProps {
  items: Clients[];
  title: string;
  error: string | null;
  selectedItem: string | null;
  handleSelectedItem: (item: string) => void;
}

const Listing: React.FC<ListingProps> = ({ items, title, error, selectedItem, handleSelectedItem }) => {
  const { user } = useContext(AuthContext);
  const api: string = useMemo(() => process.env.REACT_APP_FLASKSERVER || '', []);
  const adminEmail: string = useMemo(() => process.env.REACT_APP_ADMIN_EMAIL || '', []);

  // 배열을 복사한 후 email 기준으로 정렬
  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => a.email.localeCompare(b.email));
  }, [items]);

  // 새 Client 생성을 위한 email 상태
  const [newClientEmail, setNewClientEmail] = useState("");
  const [newClientFolderName, setNewClientFolderName] = useState("");
  const [appfolioURL, setAppfolioURL] = useState("");

  // Client 생성 핸들러
  const handleCreateClient = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      alert("User not found");
      return;
    }

    // Check for duplicate email or folder name among existing items
    const duplicate = items.find(item => item.email === newClientEmail || item.folder_name === newClientFolderName);
    if (duplicate) {
      alert("Email or folder name already exists.");
      return;
    }

    // sortedItems 배열에 있는 기존 client_id들을 Set으로 수집합니다.
    const existingIds = new Set(sortedItems.map(item => item.client_id));

    // 0001부터 9999까지 순회하며, 기존 숫자와 중복되지 않는 첫 번째 값을 찾습니다.
    let newId = null;
    for (let i = 1; i < 10000; i++) {
      const candidate = i.toString().padStart(4, "0");
      if (!existingIds.has(candidate)) {
        newId = candidate;
        break;
      }
    }

    if (newId === null) {
      throw new Error("No available 4-digit client id");
    }

    const uniqueClientId = newId;


    const payload = {
      client_id: user.id,
      client_detail: {
        client_id: uniqueClientId,
        history_id: "",
        latest_sid: "",
        email: newClientEmail,
        processed_ids: [""],
        folder_name: newClientFolderName,
        forward_to: "",
        leasing_email_from: "",
        openphone_id: "",
        phone: "",
        starting_k_id: 0,
        cc_to: [""],
        credentials: {
          token: "",
          refresh_token: "",
          token_uri: "",
          client_id: "",
          client_secret: "",
          scopes: [] as string[],
          expiry: ""
        }
      }
    };

    // ingest_client 엔드포인트로 POST 요청
    axios.post(`${api}/ingest_client`, payload)
      .then(response => {
        console.log("New Client created:", response.data);
        setNewClientEmail("");
        setNewClientFolderName("");
        setAppfolioURL("");
      })
      .catch(error => {
        console.error("Error posting new Client:", error);
      });
  };

  return (
    <div className="listing-container">
      <div className="listing-header" style={{ cursor: 'pointer' }}>
        <h2 className="listing-title">{title}</h2>
      </div>

      <div className="listing-content">
        {error ? (
          <div className="error">{error}</div>
        ) : (
          <ul className="client-list">
            {sortedItems.map((item) => (
              adminEmail === item.email ? (
                <li
                  key={item.email}
                  className={`client-item admin ${selectedItem === item.email ? 'selected' : ''}`}
                  onClick={() => handleSelectedItem(item.email)}
                >
                  <UserCircleIcon className="client-icon" />
                  <span className="client-name">{item.email}</span>
                </li>
              ) : (
                <li
                  key={item.email}
                  className={`client-item ${selectedItem === item.email ? 'selected' : ''}`}
                  onClick={() => handleSelectedItem(item.email)}
                >
                  <UserCircleIcon className="client-icon" />
                  <span className="client-name">{item.email}</span>
                </li>
              )
            ))}
          </ul>
        )}
      </div>

      <form className="create-client" onSubmit={handleCreateClient}>
        {/* <input
          type="text"
          placeholder="Enter Appfolio URL"
          value={appfolioURL}
          onChange={(e) => setAppfolioURL(e.target.value)}
          style={{ width: '100%' }}
        /> */}
        <input
          type="text"
          placeholder="Enter folder name"
          value={newClientFolderName}
          onChange={(e) => setNewClientFolderName(e.target.value)}
          required
          style={{ width: '100%' }}
        />
        <input
          type="email"
          placeholder="Enter email"
          value={newClientEmail}
          onChange={(e) => setNewClientEmail(e.target.value)}
          required
          style={{ width: '100%' }}
        />
        <button type="submit">Create</button>
      </form>
    </div>
  );
};

export default Listing;
