// src/components/DraggableList.tsx

import React, { useCallback } from 'react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import ListItem from './ListItem';
import './DraggableList.css';

interface ListItemType {
  name: string;
  size: string;
  preview: string;
  content: string;
  priority: number;
  id: string;
  description: string;
}

interface DraggableListProps {
  files: ListItemType[];
  onReorder: (reorderedItems: ListItemType[]) => void;
  handleRemoveFile: (id: string) => void;
}

const DraggableList: React.FC<DraggableListProps> = ({
  files,
  onReorder,
  handleRemoveFile,
}) => {
  const handleDragEnd = useCallback(
    (result: DropResult) => {
      console.log('Drag End:', result); // Debug log

      const { destination, source } = result;

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const reorderedItems = Array.from(files);
      const [movedItem] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, movedItem);

      // Update priority based on new order
      reorderedItems.forEach((item, index) => {
        item.priority = index + 1;
      });

      onReorder(reorderedItems);
    },
    [files, onReorder]
  );

  const handleDescriptionChange = useCallback(
    (id: string, description: string) => {
      const updatedItems = files.map((item) =>
        item.id === id ? { ...item, description } : item
      );
      onReorder(updatedItems);
    },
    [files, onReorder]
  );

  const handleContentChange = useCallback(
    (id: string, content: string) => {
      const updatedItems = files.map((item) =>
        item.id === id ? { ...item, content } : item
      );
      onReorder(updatedItems);
    },
    [files, onReorder]
  );

  // Sort files by priority
  const sortedFiles = [...files].sort((a, b) => a.priority - b.priority);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div
            className="droppable-list"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {sortedFiles.length > 0 ? (
              sortedFiles.map((item, index) => (
                <ListItem
                  key={item.id}
                  item={item}
                  index={index}
                  onDescriptionChange={handleDescriptionChange}
                  onContentChange={handleContentChange}
                  handleRemoveFile={handleRemoveFile}
                />
              ))
            ) : (
              <p>No items available.</p>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
