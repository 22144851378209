// src/components/Listing.tsx
import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import './Listing.css'; // Import the CSS file for Neumorphic styles

interface ListingProps {
  clients: string[];
  loading: boolean;
  error: string| null;
  selectedClient: string | null;
  handleSelectedClient: (client: string) => void;
}

const Listing: React.FC<ListingProps> = ({ clients, loading, error, selectedClient, handleSelectedClient }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  // Handler to toggle the listing open/close state
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="listing-container">
      <div className="listing-header" onClick={toggleOpen} style={{ cursor: 'pointer' }}>
        <h2 className="listing-title">Items</h2>
        {/* {isOpen ? (
          <ChevronUpIcon className="chevron-icon" />
        ) : (
          <ChevronDownIcon className="chevron-icon" />
        )} */}
      </div>

      {isOpen && (
        <div className="listing-content">
          {loading ? (
            <div className="loading">Loading items...</div>
          ) : error ? (
            <div className="error">{error}</div>
          ) : (
            <ul className="client-list">
              {clients.map((client) => (
                <li
                  key={client}
                  className={`client-item ${selectedClient === client ? 'selected' : ''}`}
                  onClick={() => handleSelectedClient(client)}
                >
                  <UserCircleIcon className="client-icon" />
                  <span className="client-name">{client}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default Listing;
