// src/pages/Login.tsx
import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { FaGoogle } from 'react-icons/fa';
import styles from './Login.module.css';

const Login: React.FC = () => {
  const { login } = useContext(AuthContext);

  const handleLogin = () => {
    login(); // This will redirect the user to the backend
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: '#f5f7fa',
    }}>
      <div style={{
        padding: '2rem',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        textAlign: 'center',
        maxWidth: '400px',
        width: '100%',
      }}>
        <h2 style={{ fontSize: '1.5rem', margin: '0 0 0.5rem 0', color: '#333333' }}>Welcome Back</h2>
        <p style={{ fontSize: '1rem', marginBottom: '1.5rem', color: '#666666' }}>Please sign in to continue</p>
        <button onClick={handleLogin} className={styles.googleButton}>
          <FaGoogle className={styles.icon} /> Login with Google
        </button>
      </div>
    </div>
  );
};

export default Login;
