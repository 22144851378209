// src/components/ListItem.tsx

import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import './ListItem.css';

interface ListItemProps {
  item: {
    name: string;
    size: string; // Changed 'String' to 'string'
    preview: string;
    content: string;
    id: string;
    description: string;
    priority: number;
  };
  index: number;
  onDescriptionChange: (id: string, description: string) => void;
  onContentChange: (id: string, content: string) => void;
  handleRemoveFile: (id: string) => void;
}

const ListItem: React.FC<ListItemProps> = ({ item, index, onDescriptionChange, onContentChange, handleRemoveFile }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={`list-item ${snapshot.isDragging ? 'dragging' : ''}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="item-content">
            <span>{item.priority} : {item.name}</span>
          </div>
          <div className="item-description">
            <textarea
              placeholder="Please enter a description..."
              value={item.description}
              onChange={(e) => onDescriptionChange(item.id, e.target.value)}
            />
            {/* <textarea
              value={item.content}
              onChange={(e) => onContentChange(item.id, e.target.value)}
            /> */}
          </div>
          <button onClick={() => handleRemoveFile(item.id)}>Delete</button>
        </div>
      )}
    </Draggable>
  );
};

export default ListItem;
