// src/components/Properties.tsx
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import '../components/Listing.css'; // Import the Neumorphic styles from Listing.css
import Listing from '../components/Listing';
import PropertyDetail from '../components/PropertyDetail.jsx';

// Define the Property interface based on the API response
// interface Property {
//   id: number;
//   address: string;
//   price: number;
//   bedrooms: number;
//   bathrooms: number;
//   imageUrl: string;
// }

// interface Property {
//   Kindred_ID: string;
//   address: string;
// }

interface Property  {
  Kindred_ID: number;
  address: string;
  description: string;
  "date available": string; // Keeping as string for date input
  "base monthly rent": string; // Number
  "square feet": number; // Number
  rooms: number; // Number
  baths: number; // Number
  parking: object;
  amenities: string[],
  customAmenities: string,
  restrictions: object;
  neighborhood: object;
  "mandatory monthly fees": {},
  "optional monthly fees": {},
  "optional deposits": {},
  "mandatory one-time fees": {},
  utilities: object[];
  customUtilities: string;
};

interface API {
  api: string;
}

const Properties: React.FC<API> = (api) => {
  const { user } = useContext(AuthContext);

  const [properties, setProperties] = useState<Property[]>([]);
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const API = user ? `${api}/getproperties?client_id=${user.id}`: '';

  function handleSelectedProperty(property: string) {
    setSelectedProperty(property);
  }

  // Fetch properties from the API when the component mounts
  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(API);
        setProperties(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching clients:', err);
        setError('Failed to load clients.');
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  return (
    <div className="responsive-flex">
      {/* Client List */}
      <Listing clients={properties.map(item => item.address)} loading={loading} error={error} selectedClient={selectedProperty} handleSelectedClient={handleSelectedProperty} />
      {/* Chat History */}
      {/* {properties.filter(item => item.address === selectedProperty)}; */}
      {user && selectedProperty ? <PropertyDetail api={api} selectedProperty={properties.filter(item => item.address === selectedProperty)} clientID={user.id} /> : '' }
      {/* Summary */}
      {/* <Summary selectedClient={selectedClient}/> */}
    </div>
  );
};

export default Properties;
