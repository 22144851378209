// src/components/VendorDetail.tsx
import React, { useState } from 'react';
import './VendorDetail.css'; // Ensure this path is correct

const specialtyOptions = [
  "Plumbing Supplies",
  "Electrical Supplies",
  "HVAC (Heating, Ventilation, and Air Conditioning)",
  "Carpentry and Woodwork",
  "Roofing Materials",
  "Flooring Materials",
  "Paint and Finishes",
  "Hardware and Fasteners",
  "Tools and Equipment",
  "Insulation and Weatherproofing",
  "Appliances and Fixtures",
  "Outdoor and Landscaping",
  "Concrete and Masonry",
  "Adhesives and Sealants",
  "Miscellaneous Supplies",
];

interface VendorDetailProps {
  selectedProperty: {
    vendor: string;
    "phone number": string;
    email: string;
    address: string;
    specialty: string[];
    notes: string;
    description: string;
  };
  onUpdate: (updatedVendor: VendorDetailProps['selectedProperty']) => void; // Callback to parent
}

const VendorDetail: React.FC<VendorDetailProps> = ({ selectedProperty, onUpdate }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    vendor: selectedProperty.vendor,
    address: selectedProperty.address,
    email: selectedProperty.email,
    "phone number": selectedProperty["phone number"],
    specialty: selectedProperty.specialty,
    description: selectedProperty.description,
    notes: selectedProperty.notes,
  });
  const [updateStatus, setUpdateStatus] = useState<string | null>(null);

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle specialty checkbox changes
  const handleSpecialtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setFormData(prev => {
      const newSpecialty = checked
        ? [...prev.specialty, value]
        : prev.specialty.filter(item => item !== value);
      return {
        ...prev,
        specialty: newSpecialty,
      };
    });
  };

  // Handle Edit button click
  const handleEdit = () => {
    setIsEditing(true);
    setUpdateStatus(null);
  };

  // Handle Done button click
  const handleDone = async () => {
    try {
      // Replace with your actual API endpoint for updating vendor details
      //TODO
      // const response = await axios.put(`/api/vendors/${formData.vendor}`, formData);
      // console.log('Update successful:', response.data);
      setIsEditing(false);
      setUpdateStatus('Update successful!');
      onUpdate(formData); // Notify parent of the update
    } catch (error) {
      console.error('Error updating vendor:', error);
      setUpdateStatus('Failed to update vendor.');
    }
  };

  // Handle Cancel button click
  const handleCancel = () => {
    setIsEditing(false);
    setFormData({
      vendor: selectedProperty.vendor,
      address: selectedProperty.address,
      email: selectedProperty.email,
      "phone number": selectedProperty["phone number"],
      specialty: selectedProperty.specialty,
      description: selectedProperty.description,
      notes: selectedProperty.notes,
    });
    setUpdateStatus(null);
  };


  function transformContent(code: string) {
    let html = '';
    const lines = code.split('\n');
    let inList = false;

    lines.forEach(line => {
      // 1. 구분선 변환
      if (/^---$/.test(line.trim())) {
        if (inList) {
          html += '</ul>';
          inList = false;
        }
        html += '<hr>';
        return;
      }

      // 2. 헤더 변환
      let headerMatch = line.match(/^(###|####) \*\*(.+?)\*\*$/);
      if (headerMatch) {
        if (inList) {
          html += '</ul>';
          inList = false;
        }
        const headerLevel = headerMatch[1] === '###' ? 'h3' : 'h4';
        if (headerMatch[1] === '###') {
          html += `<${headerLevel}><p><strong>${headerMatch[2]}</strong></p></${headerLevel}>`;
        } else {
          html += `<${headerLevel}><p>${headerMatch[2]}</p></${headerLevel}>`;
        }
        return;
      }

      // 3. 목록 항목 변환
      let listMatch = line.match(/^- \*\*(.+?):\*\* (.+)$/);
      if (listMatch) {
        if (!inList) {
          html += '<ul>';
          inList = true;
        }
        html += `<li>- ${listMatch[1]}: ${listMatch[2]}</li>`;
        return;
      }

      // 목록이 아니고, 목록이 열려 있다면 닫기
      if (inList && !line.startsWith('- **')) {
        html += '</ul>';
        inList = false;
      }

      // 4. 기타 텍스트 변환
      // 기울임 글씨 변환
      let transformedLine = line.replace(/\*(.+?)\*/g, '<em>$1</em>');
      // 단락 추가
      if (transformedLine.trim() !== '') {
        html += `<p>${transformedLine}</p>`;
      }
    });

    // 목록이 끝나지 않은 경우 닫기
    if (inList) {
      html += '</ul>';
    }

    return html;
  }


  return (
    <div className="vendor-detail">
      {isEditing ? (
        <div className="edit-form">
          <h2>Edit {formData.vendor}</h2>
          <div className="form-group">
            <label>Vendor Name:</label>
            <input
              type="text"
              name="vendor"
              value={formData.vendor}
              onChange={handleChange}
              disabled // Assuming vendor name is unique and not editable
            />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              type="text"
              name="phone number"
              value={formData["phone number"]}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Specialty:</label>
            <div className="checkbox-group">
              {specialtyOptions.map(option => (
                <label key={option}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={formData.specialty.includes(option)}
                    onChange={handleSpecialtyChange}
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
          <div className="form-group">
            <label>Description:</label>
            <textarea
              name="description"
              value={transformContent(formData.description)}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Notes:</label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
            />
          </div>
          <div className="button-group">
            <button onClick={handleDone} className="done-button">Done</button>
            <button onClick={handleCancel} className="cancel-button">Cancel</button>
          </div>
          {updateStatus && <p className="update-status">{updateStatus}</p>}
        </div>
      ) : (
        <div>
          <h2>{selectedProperty.vendor}</h2>
          <p><strong>Address:</strong> {selectedProperty.address}</p>
          <p><strong>Email:</strong> {selectedProperty.email}</p>
          <p><strong>Phone Number:</strong> {selectedProperty["phone number"]}</p>
          <p><strong>Specialty:</strong> {selectedProperty.specialty.join(', ')}</p>
            <p>
            <strong>Description:</strong>{" "}
            <p
              style={{ paddingLeft: '1em' }}
              dangerouslySetInnerHTML={{ __html: transformContent(selectedProperty.description) }}
            />
            </p>
          <p><strong>Notes:</strong> {selectedProperty.notes}</p>
          <button onClick={handleEdit} className="edit-button">Edit</button>
        </div>
      )}
    </div>
  );
};

export default VendorDetail;
