// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  height: 3vh;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  box-sizing: border-box;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,SAAS;EACT,WAAW;EACX,WAAW;EACX,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,yCAAyC;AAC3C","sourcesContent":[".footer {\n  /* position: fixed; */\n  bottom: 0;\n  width: 100%;\n  height: 3vh;\n  background-color: #333;\n  color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0px;\n  box-sizing: border-box;\n  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
