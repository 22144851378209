// src/Dashboard.tsx
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import PeopleIcon from '@mui/icons-material/People';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BuildIcon from '@mui/icons-material/Build';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ReTooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend, BarChart, Bar } from 'recharts';
import axios from 'axios';

// Define the Property type
interface Property {
  id: number;
  name: string;
  address: string;
  occupancyRate: number;
  rentalIncome: number;
  maintenanceRequests: number;
}

// Define the Metric type with color as string literals
interface Metric {
  title: string;
  value: string | number;
  icon: React.ReactElement;
  color: 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'info';
}

// Mock data fetching function (replace with actual API calls)
const fetchProperties = async (): Promise<Property[]> => {
  // Simulate network delay
  await new Promise((resolve) => setTimeout(resolve, 1000));

  // Return mock data
  return [
    { id: 1, name: 'Sunset Apartments', address: '123 Main St', occupancyRate: 85, rentalIncome: 30000, maintenanceRequests: 5 },
    { id: 2, name: 'Lakeside Villas', address: '456 Lake Ave', occupancyRate: 90, rentalIncome: 45000, maintenanceRequests: 3 },
    { id: 3, name: 'Downtown Condos', address: '789 Center Blvd', occupancyRate: 80, rentalIncome: 35000, maintenanceRequests: 4 },
    // Add more properties as needed
  ];
};

// Define a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Blue
      light: '#4791db',
      dark: '#115293',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff5722', // Orange
      light: '#ff8a50',
      dark: '#c41c00',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50', // Green
      light: '#80e27e',
      dark: '#087f23',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800', // Amber
      light: '#ffc947',
      dark: '#c66900',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336', // Red
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3', // Light Blue
      light: '#6ec6ff',
      dark: '#0069c0',
      contrastText: '#fff',
    },
  },
});

// Styled Drawer
const drawerWidth = 240;

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginLeft: drawerWidth,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    background: '#f4f6f8',
  },
}));

const Dashboard: React.FC = () => {
  // State for properties data
  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  // Fetch properties data on component mount
  useEffect(() => {
    const getProperties = async () => {
      setLoading(true);
      try {
        // Replace with actual API call if available
        // const response = await axios.get<Property[]>('/api/properties');
        // setProperties(response.data);

        const data = await fetchProperties();
        setProperties(data);
        setLoading(false);
      } catch (err: any) {
        setError(err.message || 'Failed to fetch properties');
        setLoading(false);
      }
    };

    getProperties();
  }, []);

  // Define metrics with specific color keys
  const metrics: Metric[] = [
    { title: 'Occupancy Rate', value: '85%', icon: <PeopleIcon />, color: 'primary' },
    { title: 'Total Rental Income', value: '$120,000', icon: <MonetizationOnIcon />, color: 'secondary' },
    { title: 'Maintenance Requests', value: 12, icon: <BuildIcon />, color: 'warning' },
  ];

  // Sample chart data (can be dynamic)
  const occupancyChartData = [
    { month: 'Jan', occupancy: 80 },
    { month: 'Feb', occupancy: 82 },
    { month: 'Mar', occupancy: 85 },
    { month: 'Apr', occupancy: 83 },
    { month: 'May', occupancy: 88 },
    { month: 'Jun', occupancy: 90 },
    // Add more data points
  ];

  const rentalIncomeChartData = [
    { month: 'Jan', income: 25000 },
    { month: 'Feb', income: 27000 },
    { month: 'Mar', income: 30000 },
    { month: 'Apr', income: 28000 },
    { month: 'May', income: 32000 },
    { month: 'Jun', income: 35000 },
    // Add more data points
  ];

  const maintenanceChartData = [
    { month: 'Jan', requests: 4 },
    { month: 'Feb', requests: 3 },
    { month: 'Mar', requests: 5 },
    { month: 'Apr', requests: 4 },
    { month: 'May', requests: 6 },
    { month: 'Jun', requests: 3 },
    // Add more data points
  ];

  const rentalIncomePieData = properties.map((property) => ({
    name: property.name,
    value: property.rentalIncome,
  }));

  const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1', '#a4de6c'];

  // Navigation menu items
  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon /> },
    { text: 'Analytics', icon: <BarChartIcon /> },
    { text: 'Reports', icon: <TableChartIcon /> },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        {/* Mobile Drawer */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <List>
            {menuItems.map((item, index) => (
              <ListItemButton key={item.text} sx={{ mt: 2 }}>
                <ListItemIcon sx={{ color: theme.palette.primary.main }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
          </List>
        </Drawer>

        {/* Main Content */}
        <div style={{ margin: '48px' }}>
          <Toolbar />
          {/* Dashboard Overview */}
          <Grid container spacing={3}>
            {metrics.map((metric) => (
              <Grid item xs={12} sm={4} key={metric.title}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: theme.palette[metric.color].light,
                  }}
                >
                  <Box sx={{ mr: 2 }}>
                    <Tooltip title={metric.title}>
                      <IconButton color={metric.color}>
                        {metric.icon}
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{metric.title}</Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {metric.value}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>

          {/* Charts */}
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} sx={{ padding: 8 }}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Occupancy Rate Over Time
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={occupancyChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis domain={[0, 100]} />
                    <ReTooltip />
                    <Line type="monotone" dataKey="occupancy" stroke={theme.palette.primary.main} activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} sx={{ padding: 8 }}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Rental Income Over Time
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={rentalIncomeChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <ReTooltip />
                    <Bar dataKey="income" fill={theme.palette.secondary.main} />
                  </BarChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} sx={{ padding: 8 }}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Maintenance Requests Over Time
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={maintenanceChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <ReTooltip />
                    <Line type="monotone" dataKey="requests" stroke={theme.palette.warning.main} activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} sx={{ padding: 8 }}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Rental Income Distribution
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={rentalIncomePieData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      fill={theme.palette.primary.main}
                      label
                    >
                      {rentalIncomePieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend />
                    <ReTooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>
          </Grid>

          {/* Data Table */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Properties
            </Typography>
            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}
            {!loading && !error && (
              <TableContainer component={Paper}>
                <Table aria-label="properties table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Occupancy Rate</TableCell>
                      <TableCell>Rental Income</TableCell>
                      <TableCell>Maintenance Requests</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {properties.map((property) => (
                      <TableRow key={property.id} hover>
                        <TableCell>{property.id}</TableCell>
                        <TableCell>{property.name}</TableCell>
                        <TableCell>{property.address}</TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              sx={{
                                width: 10,
                                height: 10,
                                backgroundColor:
                                  property.occupancyRate > 85
                                    ? theme.palette.success.main
                                    : property.occupancyRate > 70
                                    ? theme.palette.warning.main
                                    : theme.palette.error.main,
                                borderRadius: '50%',
                                mr: 1,
                              }}
                            ></Box>
                            {property.occupancyRate}%
                          </Box>
                        </TableCell>
                        <TableCell>${property.rentalIncome.toLocaleString()}</TableCell>
                        <TableCell>{property.maintenanceRequests}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
