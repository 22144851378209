// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* Custom responsive flex class */
.responsive-flex {
  flex: 1 1;
  display: flex;
  height: 100%;
  /* gap: 20px; */
  /* padding: 20px; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Prompts.css"],"names":[],"mappings":";;AAEA,iCAAiC;AACjC;EACE,SAAO;EACP,aAAa;EACb,YAAY;EACZ,eAAe;EACf,mBAAmB;AACrB","sourcesContent":["\n\n/* Custom responsive flex class */\n.responsive-flex {\n  flex: 1;\n  display: flex;\n  height: 100%;\n  /* gap: 20px; */\n  /* padding: 20px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
