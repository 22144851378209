// src/App.tsx
import React, { useMemo, useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import NewLeads from './pages/NewLeads';
import NewLeads_maint from './pages/NewLeads_maint';
import Properties from './pages/Properties';
import ManualReview from './pages/ManualReview';
import FileUploader from './pages/FileUploader';
import Prompts from './pages/Prompts';
import Register from './pages/Register';
import Subscriptors from './pages/Subscriptors';
import Dashboard from './pages/Dashboard';
import RepairTickets from './pages/RepairTickets';
import Vendors from './pages/Vendors';
import MaintCategories from './pages/MaintCategories';
import PropertiesAndTenants from './pages/PropertiesAndTenants';
import Settings from './pages/Settings';
import Logout from './pages/Logout';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedLayout from './components/ProtectedLayout';
import houseImage from './assets/house2.jpg';
import './Neumorphism.css';

import { useSocket } from './contexts/SocketContext';
import { AuthContext } from './contexts/AuthContext';

const App: React.FC = () => {
  const api: string = useMemo(() => process.env.REACT_APP_FLASKSERVER || '', []);
  if (!api) {
    console.error('FLASKSERVER environment variable is not set');
  }

  // const { user } = useContext(AuthContext);
  // const socket = useSocket();
  // const [error, setError] = React.useState<string | null>(null);

  // useEffect(() => {
  //   console.log('[DEBUG] App.tsx useEffect 실행됨');
  //   if (!api) {
  //     console.error('[ERROR] Socket server URL이 설정되지 않음');
  //     return;
  //   }
  //   if (!user) {
  //     console.warn('[WARN] User is not logged in - 현재 로그인 상태:', user);
  //     return;
  //   }
  //   console.log('[DEBUG] 등록 전 socket id:', socket.id);
    
  //   const handleAlertCrawler = (data: any) => {
  //     console.log('[DEBUG] App.tsx handleAlertCrawler 호출됨:', data);
  //     if (data.error) {
  //       setError(data.error);
  //     } else {
  //       if (data.clientName) {
  //         alert(`Crawling complete. ${data.clientName}`);
  //       } else {
  //         alert(`RAG complete.`);
  //       }
  //     }
  //   };
  
  //   socket.on("alert_crawler", handleAlertCrawler);
  //   console.log('[DEBUG] App.tsx alert_crawler 이벤트 리스너 등록 완료');
    
  //   return () => {
  //     console.log('[DEBUG] App.tsx alert_crawler 이벤트 리스너 해제');
  //     socket.off('alert_crawler', handleAlertCrawler);
  //   };
  // }, [api, user, socket]);

  
  const routes = useMemo(() => (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route element={<ProtectedLayout />}>
            <Route path="/new-leads" element={<NewLeads api={api} listType='crm'/>} />
            <Route path="/properties" element={<Properties api={api}/>} />
            <Route path="/manual-review" element={<NewLeads api={api} listType='crm_flag'/>} />
            <Route path="/upload-documents" element={<FileUploader api={api}/>} />
            <Route path="/prompts" element={<Prompts api={api}/>} />
            <Route path="/register" element={<Register api={api}/>} />
            <Route path="/subscriptors" element={<Subscriptors api={api}/>} />
            <Route path="/work-orders" element={<NewLeads_maint api={api} listType='WO'/>} />
            <Route path="/vendors" element={<Vendors api={api}/>} />
            <Route path="/Maintenance Categories" element={<MaintCategories api={api}/>} />
            <Route path="/work-orders-flag" element={<NewLeads_maint api={api} listType='WO_flag'/>} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/repair-tickets" element={<RepairTickets api={api}/>} />   
            <Route path="/propertiesAndTenants" element={<PropertiesAndTenants/>} />
            {/* <Route path="/settings" element={<Settings />} /> */}
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<Navigate to="/new-leads" replace />} />
          </Route>
        </Route>
      </Routes>
    </>
  ), [api]);

  return routes;
};

export default App;
