// src/components/Chats.tsx
import React, { useState, useEffect, useRef } from "react";
import { UserCircleIcon } from '@heroicons/react/24/solid';
import axios from "axios";
import Papa from "papaparse"; // Import Papa Parse
import './Chats.css'; // Import the CSS file for Neumorphic styles
import logo from '../assets/KindredAI_logo_mini.png';

interface ChatProps {
  api: string | null;
  selectedClient: string | null;
  client_id: string | null;
}

interface ChatMessage {
  timestamp: string; // When the message was sent
  channel: string;   // Channel of communication (e.g., email, sms, voicemail)
  details: string;   // Main message or interaction details
  notes: string;     // Indicates if the message was sent or received
  reasoning?: string; // Reasoning behind the agent's message (optional)
}

const Chats: React.FC<ChatProps> = ({ api, selectedClient, client_id }) => {
  const [chats, setChats] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [newMessage, setNewMessage] = useState<string>("");

  // New state for selected channel
  const [selectedChannel, setSelectedChannel] = useState<string>("sms"); // Default to "sms"

  // New state for expanded messages
  const [expandedMessages, setExpandedMessages] = useState<Set<number>>(new Set());

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    // Create the new chat message
    const newChat: ChatMessage = {
      timestamp: new Date().toISOString(),
      channel: selectedChannel, // Use selected channel
      details: newMessage,
      notes: "sent",
    };
    setChats([...chats, newChat]);
    setNewMessage("");

    try {
      // Prepare the payload
      const payload = {
        email: selectedClient, // Assuming you need to identify the client
        channel: selectedChannel,
        message: newMessage,
        client_id: client_id
      };

      // Send POST request to the Flask server
      const response = await axios.post(
        `${api}/send_msg_via_frontend`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response as needed
      const flaggedPeople = response.data; // Adjust based on actual response structure
      console.log("Flagged People:", flaggedPeople);

      // Optionally, update the UI or state based on the response
      // For example, you could add a system message or highlight flagged messages
    } catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send message.");
    }
  };

  // Handler to toggle message expansion
  const toggleMessage = (index: number) => {
    setExpandedMessages(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  // Fetch chats when selectedClient changes
  useEffect(() => {
    const fetchChats = async () => {
      if (!selectedClient) {
        setChats([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError("");

      try {
        const response = await axios.get(`${api}/get_interactions?client_id=${client_id}&email=${selectedClient}`,
          { responseType: "blob" }
        );
        const csvText = await response.data.text(); // Convert blob to text

        // Parse CSV using Papa Parse
        Papa.parse<ChatMessage>(csvText, {
          header: true, // Assumes first row is header
          skipEmptyLines: true,
          complete: (results) => {
            const parsedData = results.data as ChatMessage[];
            setChats(parsedData);
            setLoading(false);
            scrollToBottom();
          },
          error: (err) => {
            console.error("Error parsing CSV:", err);
            setError("Failed to parse CSV data.");
            setLoading(false);
          },
        });
      } catch (err) {
        console.error("Error fetching chats:", err);
        setError("Failed to load chats.");
        setLoading(false);
      }
    };

    fetchChats();
  }, [selectedClient, api, client_id]);

  // Determine the sender based on 'notes' field
  const getSender = (notes: string): "agent" | "client" => {
    return notes.toLowerCase() === "sent" ? "agent" : "client";
  };

  // Handler for channel selection
  const handleChannelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedChannel(e.target.value);
  };

  return (
    <div className="chat-wrapper">
      {selectedClient ? (
          <div className="chats-content">
            <h2 className="chats-title">
              {selectedClient}'s Chat History
            </h2>
            <div className="chats-messages">
              {loading && <p className="loading-message">Loading chats...</p>}
              {error && <p className="error-message">{error}</p>}
              {!loading && !error && chats.length > 0 ? (
                chats.map((chat, index) => (
                  <div key={`${chat.timestamp}-${index}`} className="chat-item">
                    {getSender(chat.notes) === "agent" ? (
                      <img
                        src={logo}
                        alt="KindredPM Logo"
                        className="client-icon agent"
                      />
                    ) : (
                      <UserCircleIcon
                        className="client-icon client"
                      />
                    )}
                    <div
                      className={`chat-bubble ${getSender(chat.notes) === "agent" ? "agent" : "client"}`}
                      onClick={() => getSender(chat.notes) === "agent" && toggleMessage(index)}
                      style={getSender(chat.notes) === "agent" ? { cursor: 'pointer' } : {}}
                    >
                      <div className="chat-content">                    
                        {/* Chat Details */}
                        <p className={`chat-details ${getSender(chat.notes) === "agent" ? "agent" : "client"}`}>
                          {chat.details}
                        </p>
                        {/* Reasoning (conditionally rendered) */}
                        {getSender(chat.notes) === "agent" && expandedMessages.has(index) && chat.reasoning && (
                          <p className="chat-reasoning">
                            {chat.reasoning}
                          </p>
                        )}
                        {/* Timestamp */}
                        <span className="chat-timestamp">
                          {chat.channel} {new Date(chat.timestamp).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                !loading &&
                !error && <p className="no-chats-message">No chats available for the selected client.</p>
              )}
              <div ref={messagesEndRef} />
            </div>
            {/* Chat Input Area Positioned Below the Chats Container */}
            <div className="chat-input-container">
              {/* Channel Selector */}
              <div className="channel-selector">
                <label htmlFor="channel-select" className="channel-label">Channel:</label>
                <select
                  id="channel-select"
                  className="channel-select"
                  value={selectedChannel}
                  onChange={handleChannelChange}
                  aria-label="Select communication channel"
                >
                  <option value="sms">sms</option>
                  <option value="email">email</option>
                  {/* Add more channels as needed */}
                </select>
              </div>
              {/* Chat Input Field */}
              <input
                type="text"
                className="chat-input"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                aria-label="Type your message"
              />
              <button
                className="send-button"
                onClick={handleSendMessage}
                aria-label="Send message"
              >
                Send
              </button>
            </div>
          </div>
      ) : (
        <div className="no-client-selected">
          <p>Select a client to view chat history.</p>
        </div>
      )}
      
    </div>
  );
};

export default Chats;
