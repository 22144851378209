// src/components/ProtectedLayout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../pages/Navbar'; // Adjusted path
import Footer from './Footer';

const ProtectedLayout: React.FC = () => {
  return (
    <div className="app-container">
      {/* Main Content */}
      <div className="main-content">
        <Navbar />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default ProtectedLayout;
