// src/components/Prompts.tsx
import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import TextContents from '../utils/TextContents';
import JsonContents from '../utils/JsonContents';
// import Policies from '../pages/Policies';
import './Prompts.css'; // Prompts.css 임포트

interface PromptsProps {
  api: string;
}

const Prompts: React.FC<PromptsProps> = ( {api} ) => {
  const { user } = useContext(AuthContext);

  return (
    <div className="responsive-flex">
      {user ? <TextContents api={api} client_id={user.id} /> : ''}
      {user ? <JsonContents api={api} client_id={user.id} /> : ''}
      {/* {user ? <Policies api={api} clients={user.id} /> : ''} */}
    </div>
  );
};

export default Prompts;