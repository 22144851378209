// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Login.module.css */

.Login_googleButton__-QtIx {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #4285f4;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .Login_googleButton__-QtIx:hover {
    background-color: #3367d6;
  }
  
  .Login_icon__Ff30R {
    margin-right: 0.5rem;
    font-size: 1.25rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Login.module.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,oBAAoB;IACpB,kBAAkB;EACpB","sourcesContent":["/* src/pages/Login.module.css */\n\n.googleButton {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0.75rem 1rem;\n    font-size: 1rem;\n    font-weight: bold;\n    background-color: #4285f4;\n    color: #ffffff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .googleButton:hover {\n    background-color: #3367d6;\n  }\n  \n  .icon {\n    margin-right: 0.5rem;\n    font-size: 1.25rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"googleButton": `Login_googleButton__-QtIx`,
	"icon": `Login_icon__Ff30R`
};
export default ___CSS_LOADER_EXPORT___;
