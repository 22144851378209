// src/components/Navbar.tsx
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import {
  ChatBubbleBottomCenterTextIcon,
  UsersIcon,
  FolderIcon,
  FlagIcon,
  ArrowUpTrayIcon,
  CommandLineIcon,
  HomeIcon,
  CogIcon,
  ChartBarIcon,
  ClipboardDocumentListIcon,
  ArrowLeftStartOnRectangleIcon
} from '@heroicons/react/24/outline';
import logo from '../assets/KPM_logo_black.png';
import './Navbar.css';

const Navbar: React.FC = () => {
  const { logout } = useContext(AuthContext);

  const handleLogout = async () => {
    await logout();
    // Optionally, redirect to login
    // window.location.href = '/';
  };

  const navAgents = [
    {
      role: "Leasing",
      subRole: [
        { name: 'New Leads', path: '/new-leads', icon: ChatBubbleBottomCenterTextIcon },
        { name: 'Properties', path: '/properties', icon: HomeIcon },
        { name: 'Manual Review', path: '/manual-review', icon: FlagIcon },
        { name: 'Upload Documents', path: '/upload-documents', icon: ArrowUpTrayIcon },
        { name: 'Prompts', path: '/prompts', icon: CommandLineIcon },
      ]
    },
    {
      role: "Maintenance",
      subRole: [
        { name: 'Dashboard', path: '/dashboard', icon: ChartBarIcon },
        { name: 'Repair Tickets', path: '/repair-tickets', icon: FolderIcon },
        { name: 'Vendors', path: '/vendors', icon: UsersIcon },
        { name: 'Properties & Tenants', path: '/propertiesAndTenants', icon: ClipboardDocumentListIcon },
        { name: 'Settings', path: '/settings', icon: CogIcon },
        { name: 'Logout', path: '/logout', icon: ArrowLeftStartOnRectangleIcon }
      ]
    }
  ];

  return (
    <div className="navbar-container">
      {/* Neumorphic Logo Section */}
      <div className="logo-container">
        <img src={logo} alt="KindredPM Logo" className="logo-image" />
      </div>

      {/* Navigation Links */}
      <nav className="nav-links">
        {navAgents.map((agent) => (
          <div key={agent.role}> {/* key 추가 */}
            <div className='role-item'>
              <h3>{agent.role}</h3>
            </div>
            {agent.subRole.map((item) => (
              <NavLink
                key={item.name}
                to={item.path}
                className={({ isActive }) =>
                  `nav-item ${isActive ? 'active' : ''}`
                }
                onClick={item.name === 'Logout' ? handleLogout : undefined} // Logout 클릭 시 handleLogout 호출
              >
                {/* Icon */}
                <item.icon className="nav-icon" />
                <span className="nav-text">{item.name}</span>
              </NavLink>
            ))}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default Navbar;
