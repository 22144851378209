// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* Custom responsive flex class */
.responsive-flex {
  flex: 1 1;
  display: flex;
  height: 100%;
  /* gap: 20px; */
  /* padding: 20px; */
}



/* Optional: Styling for flex items */
.flex-item {
  background-color: #3b82f6; /* Tailwind's blue-500 equivalent */
  color: white;
  padding: 20px;
  border-radius: 8px;
  flex: 1 1; /* Optional: Allows items to grow and fill available space */
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/NewLeads.css"],"names":[],"mappings":";;AAEA,iCAAiC;AACjC;EACE,SAAO;EACP,aAAa;EACb,YAAY;EACZ,eAAe;EACf,mBAAmB;AACrB;;;;AAIA,qCAAqC;AACrC;EACE,yBAAyB,EAAE,mCAAmC;EAC9D,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,SAAO,EAAE,4DAA4D;EACrE,kBAAkB;AACpB","sourcesContent":["\n\n/* Custom responsive flex class */\n.responsive-flex {\n  flex: 1;\n  display: flex;\n  height: 100%;\n  /* gap: 20px; */\n  /* padding: 20px; */\n}\n\n\n\n/* Optional: Styling for flex items */\n.flex-item {\n  background-color: #3b82f6; /* Tailwind's blue-500 equivalent */\n  color: white;\n  padding: 20px;\n  border-radius: 8px;\n  flex: 1; /* Optional: Allows items to grow and fill available space */\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
