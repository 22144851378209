// src/components/Logout.tsx
import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const Logout: React.FC = () => {
  const { logout } = useContext(AuthContext);

  const handleLogout = async () => {
    await logout();
    // Optionally, redirect to login
    // window.location.href = '/';
  };
  return (
    <button onClick={handleLogout}>Logout</button>
  );
};

export default Logout;