// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/DraggableList.css */

.droppable-list {
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
  background-color: #f4f5f7;
  border-radius: 8px;
  min-height: 100px;
}

.droppable-list > .list-item + .list-item {
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DraggableList.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* src/DraggableList.css */\n\n.droppable-list {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 16px;\n  background-color: #f4f5f7;\n  border-radius: 8px;\n  min-height: 100px;\n}\n\n.droppable-list > .list-item + .list-item {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
