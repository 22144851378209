import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PropertyDetail.css';

// Define a set of field names that should be treated as numbers
const numberFields = new Set([
  'base monthly rent',
  'square feet',
  'rooms',
  'baths',
  // Neighborhood distance fields
  'neighborhood.nearest daycare.distance_in_miles',
  'neighborhood.nearest kindergarten.distance_in_miles',
  'neighborhood.nearest elementary school.distance_in_miles',
  'neighborhood.nearest middle school.distance_in_miles',
  'neighborhood.nearest high school.distance_in_miles',
  'neighborhood.nearest university.distance_in_miles',
  'neighborhood.nearest public transport.distance_in_miles',
  // Fees categories (values are numbers)
  'mandatory monthly fees',
  'optional monthly fees',
  'optional deposits',
  'mandatory one-time fees',
]);

// Initial state for a new property with appropriate default types
const initialPropertyState = {
  Kindred_ID: '',
  address: '',
  description: '',
  "date available": '', // Keeping as string for date input
  "base monthly rent": 0, // Number
  "square feet": 0, // Number
  rooms: 0, // Number
  baths: 0, // Number
  parking: {
    value: false, // Boolean
    details: '',
  },
  amenities: [],
  customAmenities: '',
  restrictions: {
    "no smoking": { value: false, details: '' },
    "no pets": { value: false, details: '' },
    "no subletting": { value: false, details: '' },
    "no unlimited tenants": { value: false, details: '' },
    "no modifying property": { value: false, details: '' },
    "no short-term rentals": { value: false, details: '' },
  },
  neighborhood: {
    "nearest daycare": { name: '', distance_in_miles: 0 }, // Number
    "nearest kindergarten": { name: '', distance_in_miles: 0 }, // Number
    "nearest elementary school": { name: '', distance_in_miles: 0 }, // Number
    "nearest middle school": { name: '', distance_in_miles: 0 }, // Number
    "nearest high school": { name: '', distance_in_miles: 0 }, // Number
    "nearest university": { name: '', distance_in_miles: 0 }, // Number
    "nearest public transport": { name: '', distance_in_miles: 0 }, // Number
  },
  "mandatory monthly fees": {},
  "optional monthly fees": {},
  "optional deposits": {},
  "mandatory one-time fees": {},
  utilities: [
    { name: 'Water', included: false },
    { name: 'Sewer', included: true },
    { name: 'Trash', included: true },
    { name: 'Electricity', included: false },
    { name: 'Gas', included: false },
    { name: 'Internet', included: false },
  ],
  customUtilities: ''
};

const PropertyDetail = ({ api, selectedProperty, clientID }) => {
  const [properties, setProperties] = useState(selectedProperty);
  const [filteredProperties, setFilteredProperties] = useState([selectedProperty]);
  const [tenantQualifications, setTenantQualifications] = useState({});
  const [specificPolicies, setSpecificPolicies] = useState({}); 
  const [showAddPropertyForm, setShowAddPropertyForm] = useState(false);
  const [newProperty, setNewProperty] = useState(initialPropertyState);
  const [feedbackMessage, setFeedbackMessage] = useState({ type: '', message: '' });
  const [expandedPropertyId, setExpandedPropertyId] = useState(selectedProperty[0].Kindred_ID);
  const [editingProperty, setEditingProperty] = useState(null);
  const [editingQualifications, setEditingQualifications] = useState(null);
  const [editingPolicies, setEditingPolicies] = useState(null);
  useEffect(() => {
    setExpandedPropertyId(selectedProperty[0].Kindred_ID);
  }, [selectedProperty]);

  // useEffect(() => {
  //   const fetchProperties = async () => {
  //     try {
  //       const response = await axios.get('${api}/getproperties', {
  //         headers: { 'ngrok-skip-browser-warning': 'true' },
  //       });
  //       setProperties(response.data);
  //       setFilteredProperties(response.data);
  //     } catch (error) {
  //       console.error('Error fetching properties:', error);
  //       setFeedbackMessage({ type: 'error', message: 'Error fetching properties. Please try again later.' });
  //     }
  //   };
  //   fetchProperties();
  // }, []);

  // Fetch tenant qualifications based on the clicked property ID
  useEffect (() => {
    if (expandedPropertyId) {
      fetchTenantQualifications(expandedPropertyId);
      fetchSpecificPolicies(expandedPropertyId);
    }
  }, [expandedPropertyId]);
  const fetchTenantQualifications = async (Kindred_ID) => {
    try {
      const response = await axios.get(`${api}/qualify_tenants?client_id=${clientID}&Kindred_ID=${Kindred_ID}`, {
      });
      setTenantQualifications((prev) => ({
        ...prev,
        [Kindred_ID]: response.data, // Store the qualification data for the specific property
      }));
    } catch (error) {
      console.error('Error fetching tenant qualifications:', error);
    }
  };

  // Fetch specific policies based on the clicked property ID
  const fetchSpecificPolicies = async (Kindred_ID) => {
    try {
      const response = await axios.get(`${api}/get_specific_policies?client_id=${clientID}&ID=${Kindred_ID}`, {
      });
      setSpecificPolicies((prev) => ({
        ...prev,
        [Kindred_ID]: response.data, // Store the policy data for the specific property
      }));
    } catch (error) {
      console.error('Error fetching specific policies:', error);
    }
  };

  const resetForm = () => {
    setNewProperty(initialPropertyState);
    setFeedbackMessage({ type: '', message: '' });
  };

  const handleAddPropertyClick = () => {
    setShowAddPropertyForm((prev) => !prev);
    if (showAddPropertyForm) resetForm();
    if (editingProperty) setEditingProperty(null);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, dataset } = e.target;
    const [section, key, subKey] = name.split('.');

    setNewProperty((prev) => {
      let updated = { ...prev };

      if (section === 'utilities' && key === 'included') {
        const index = parseInt(dataset.index, 10);
        updated.utilities[index].included = checked;
      } else if (section === 'parking') {
        updated.parking = { ...prev.parking, [key]: type === 'checkbox' ? checked : value };
      } else if (section === 'restrictions') {
        updated.restrictions = {
          ...prev.restrictions,
          [key]: { ...prev.restrictions[key], [subKey]: type === 'checkbox' ? checked : value },
        };
      } else if (section === 'neighborhood') {
        updated.neighborhood = {
          ...prev.neighborhood,
          [key]: {
            ...prev.neighborhood[key],
            [subKey]:
              subKey === 'distance_in_miles'
                ? value === '' ? 0 : Number(value)
                : value,
          },
        };
      } else if (
        ["mandatory monthly fees", "optional monthly fees", "optional deposits", "mandatory one-time fees"].includes(section)
      ) {
        if (type === 'number') {
          updated[section] = {
            ...prev[section],
            [key]: value === '' ? 0 : Number(value),
          };
        } else {
          updated[section] = {
            ...prev[section],
            [key]: value,
          };
        }
      } else if (section === 'amenities') {
        const amenities = checked
          ? [...prev.amenities, value]
          : prev.amenities.filter((item) => item !== value);
        updated.amenities = amenities;
      } else if (section === 'customAmenities') {
        updated.customAmenities = value;
      } else if (section === 'customUtilities') {
        updated.customUtilities = value;
      } else {
        // Determine if the field should be a number
        const fullFieldName = name;
        const isNumber = numberFields.has(fullFieldName);
        updated[name] = type === 'checkbox' ? checked : (isNumber ? (value === '' ? 0 : Number(value)) : value);
      }

      return updated;
    });
  };

  const handleAddCustomItem = (section, field) => {
    const value = newProperty[field].trim();
    if (!value) return;

    if (section === 'amenities') {
      setNewProperty((prev) => ({
        ...prev,
        amenities: [...prev.amenities, value],
        customAmenities: '',
      }));
    } else if (section === 'utilities') {
      setNewProperty((prev) => ({
        ...prev,
        utilities: [...prev.utilities, { name: value, included: false }],
        customUtilities: '',
      }));
    }
  };

  const handleRemoveFee = (category, feeName) => {
    setNewProperty((prev) => {
      const updated = { ...prev[category] };
      delete updated[feeName];
      return { ...prev, [category]: updated };
    });
  };

  const handleFeeChange = (category, feeName, amount) => {
    setNewProperty((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [feeName]: Number(amount),
      },
    }));
  };

  const handleAddFee = (category, feeName, feeAmount) => {
    if (feeName && feeAmount) {
      setNewProperty((prev) => ({
        ...prev,
        [category]: {
          ...prev[category],
          [feeName.trim()]: Number(feeAmount),
        },
      }));
    }
  };

  const handleSubmitProperty = async (e) => {
    e.preventDefault();
    let finalProperty = { ...newProperty };

    if (!finalProperty.Kindred_ID) {
      const existingIDs = properties.map((prop) => prop.Kindred_ID);
      finalProperty.Kindred_ID = existingIDs.length > 0 ? Math.max(...existingIDs) + 1 : 1001;
    }

    // Transform utilities into separate arrays
    finalProperty["utilities included in rent"] = finalProperty.utilities
      .filter((u) => u.included)
      .map((u) => u.name);
    finalProperty["utilities not included in rent"] = finalProperty.utilities
      .filter((u) => !u.included)
      .map((u) => u.name);
    delete finalProperty.utilities;

    try {
      const response = await axios.post('${api}/addproperty', finalProperty, {
        headers: { 'Content-Type': 'application/json' },
      });

      if ([200, 201].includes(response.status)) {
        if (editingProperty) {
          setProperties((prev) =>
            prev.map((prop) => (prop.Kindred_ID === editingProperty.Kindred_ID ? finalProperty : prop))
          );
          setFilteredProperties((prev) =>
            prev.map((prop) => (prop.Kindred_ID === editingProperty.Kindred_ID ? finalProperty : prop))
          );
          setFeedbackMessage({ type: 'success', message: 'Property updated successfully!' });
          setEditingProperty(null);
          setExpandedPropertyId(null);
        } else {
          setProperties((prev) => [...prev, finalProperty]);
          setFilteredProperties((prev) => [...prev, finalProperty]);
          setFeedbackMessage({ type: 'success', message: 'New property added successfully!' });
        }
        resetForm();
        setShowAddPropertyForm(false);
      } else {
        setFeedbackMessage({ type: 'error', message: `Failed to add/update property: ${response.statusText}` });
      }
    } catch (error) {
      console.error('Error submitting property:', error);
      // Extract a meaningful error message
      let errorMsg = 'An unexpected error occurred.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.message) {
        errorMsg = error.message;
      }
      setFeedbackMessage({ type: 'error', message: `Failed to add/update property: ${errorMsg}` });
    }
  };

  const toggleExpansion = (id) => {
    setExpandedPropertyId((prev) => (prev === id ? null : id));
    fetchTenantQualifications(id);
    fetchSpecificPolicies(id);
    if (editingProperty && editingProperty.Kindred_ID !== id) {
      
      setEditingProperty(null);
      resetForm();
      setShowAddPropertyForm(false);
    }
  };

  const handleEditClick = (type, id) => {
    if (type === 'property') {
      const property = properties.find((prop) => prop.Kindred_ID === id);
      setEditingProperty(property);
    
      setNewProperty({
        ...property,
        parking: {
          value: property.parking.value,
          details: property.parking.details,
        },
        utilities: [
          ...property["utilities included in rent"].map((name) => ({ name, included: true })),
          ...property["utilities not included in rent"].map((name) => ({ name, included: false })),
        ],
      });
      setFeedbackMessage({ type: '', message: '' });
      setExpandedPropertyId(property.Kindred_ID); // Ensure only this property is expanded
  
    } else if (type === 'qualifying') {
      handleEditQualifications(id);
    } else if (type === 'policy') {
      handleEditPolicies(id);
    }
  };
  
    // Handler to initiate editing Tenant Qualifications
  const handleEditQualifications = (id) => {
    const qualification = tenantQualifications[id];
    setEditingQualifications({ id, data: { ...qualification } });
  };

  // Handler to initiate editing Specific Policies
  const handleEditPolicies = (id) => {
    const policy = specificPolicies[id];
    setEditingPolicies({ id, data: { ...policy } });
  };

    // Handler for Tenant Qualifications Form Changes
  const handleQualificationsChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditingQualifications((prev) => {
      const updatedData = { ...prev.data };

      if (name === 'allowPriorConvictions') {
        updatedData['qualifying criteria']['allow prior convictions'] = checked;
      } else if (name === 'monthlyRentMultiple') {
        updatedData['monthly rent multiple for security deposit'] = parseFloat(value) || 0;
      } else if (name === 'incomeMultiple') {
        updatedData['qualifying criteria']['income multiple for qualifying'] = parseFloat(value) || 0;
      } else if (name === 'maxHousehold') {
        updatedData['qualifying criteria']['maximum number of people in household'] = parseInt(value, 10) || 0;
      } else if (name === 'minCreditScore') {
        updatedData['qualifying criteria']['minimum credit score'] = parseInt(value, 10) || 0;
      }

      return { ...prev, data: updatedData };
    });
  };

  // Handler for Specific Policies Form Changes
  const handlePoliciesChange = (e) => {
    const { name, value } = e.target;
    setEditingPolicies((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };
  // Submit Handler for Tenant Qualifications
  const handleSubmitQualifications = async (e) => {
    e.preventDefault();
    const { id, data } = editingQualifications;

    try {
      const response = await axios.post(`${api}/update_qualifications`, {
        Kindred_ID: id,
        ...data,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if ([200, 201].includes(response.status)) {
        setTenantQualifications((prev) => ({
          ...prev,
          [id]: data,
        }));
        setFeedbackMessage({ type: 'success', message: 'Tenant Qualifications updated successfully!' });
        setEditingQualifications(null);
      } else {
        setFeedbackMessage({ type: 'error', message: `Failed to update qualifications: ${response.statusText}` });
      }
    } catch (error) {
      console.error('Error updating tenant qualifications:', error);
      let errorMsg = 'An unexpected error occurred.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.message) {
        errorMsg = error.message;
      }
      setFeedbackMessage({ type: 'error', message: `Failed to update qualifications: ${errorMsg}` });
    }
  };

  // Submit Handler for Specific Policies
  const handleSubmitPolicies = async (e) => {
    e.preventDefault();
    const { id, data } = editingPolicies;

    try {
      const response = await axios.post(`${api}/update_policies`, {
        Kindred_ID: id,
        ...data,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if ([200, 201].includes(response.status)) {
        setSpecificPolicies((prev) => ({
          ...prev,
          [id]: data,
        }));
        setFeedbackMessage({ type: 'success', message: 'Specific Policies updated successfully!' });
        setEditingPolicies(null);
      } else {
        setFeedbackMessage({ type: 'error', message: `Failed to update policies: ${response.statusText}` });
      }
    } catch (error) {
      console.error('Error updating specific policies:', error);
      let errorMsg = 'An unexpected error occurred.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.message) {
        errorMsg = error.message;
      }
      setFeedbackMessage({ type: 'error', message: `Failed to update policies: ${errorMsg}` });
    }
  };

  return (
    <div className="property-container">
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold">Property</h1>
        <div className="flex space-x-2">
          {/* <button
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded shadow"
            onClick={handleAddPropertyClick}
          >
            {showAddPropertyForm ? 'Cancel' : 'Add Property'}
          </button>
          <button
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded shadow"
            onClick={() => alert('Add Policy functionality to be implemented')}
          >
            Add Policy
          </button> */}
        </div>
      </div>

      {/* Global Feedback Message */}
      {feedbackMessage.message && !showAddPropertyForm && (
        <div
          className={`mb-4 p-4 rounded ${
            feedbackMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          }`}
        >
          {feedbackMessage.message}
        </div>
      )}

      {/* Add Property Form */}
      {showAddPropertyForm && !editingProperty && (
        <form onSubmit={handleSubmitProperty} className="mb-6 p-6 bg-white border border-gray-200 rounded-lg shadow-lg ">
          {/* Feedback Message Inside the Form */}
          {feedbackMessage.message && (
            <div
              className={`mb-4 p-4 rounded ${
                feedbackMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
              }`}
            >
              {feedbackMessage.message}
            </div>
          )}

          <h2 className="text-xl font-semibold text-gray-700 mb-4">Add New Property</h2>

          {/* Common Input Fields */}
          {[
            { label: 'Address', name: 'address', type: 'text', required: true },
            { label: 'Description', name: 'description', type: 'textarea', required: true },
            { label: 'Kindred ID', name: 'Kindred_ID', type: 'text', required: false, disabled: true },
            { label: 'Date Available', name: 'date available', type: 'date', required: true },
            { label: 'Base Monthly Rent ($)', name: 'base monthly rent', type: 'number', required: true },
            { label: 'Square Feet', name: 'square feet', type: 'number', required: true },
            { label: 'Rooms', name: 'rooms', type: 'number', required: true },
            { label: 'Bathrooms', name: 'baths', type: 'number', required: true },
          ].map((field) =>
            field.type === 'textarea' ? (
              <div className="mb-4" key={field.name}>
                <label className="block text-gray-700 font-semibold mb-2">{field.label}:</label>
                <textarea
                  name={field.name}
                  value={newProperty[field.name]}
                  onChange={handleInputChange}
                  required={field.required}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  rows="4"
                  disabled={field.disabled}
                ></textarea>
              </div>
            ) : field.name === 'Kindred_ID' ? (
              <div className="mb-4" key={field.name}>
                <label className="block text-gray-700 font-semibold mb-2">{field.label}:</label>
                <input
                  type={field.type}
                  name={field.name}
                  value={newProperty[field.name]}
                  onChange={handleInputChange}
                  required={field.required}
                  className={`w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 bg-gray-100 cursor-not-allowed`}
                  placeholder={`Auto-generated ${field.label.toLowerCase()}`}
                  disabled
                />
              </div>
            ) : (
              <div className="mb-4" key={field.name}>
                <label className="block text-gray-700 font-semibold mb-2">{field.label}:</label>
                <input
                  type={field.type}
                  name={field.name}
                  value={field.type === 'date' ? newProperty[field.name].split('T')[0] : newProperty[field.name]}
                  onChange={handleInputChange}
                  required={field.required}
                  className={`w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 ${
                    field.name === 'Kindred_ID' ? 'bg-gray-100 cursor-not-allowed' : ''
                  }`}
                  placeholder={field.type === 'date' ? 'Select date' : `Enter ${field.label.toLowerCase()}`}
                  disabled={field.disabled}
                />
              </div>
            )
          )}

          {/* Parking */}
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">Parking Available:</label>
            <div className="flex items-center mb-2">
              <label className="mr-4 flex items-center">
                <input
                  type="checkbox"
                  name="parking.value"
                  checked={newProperty.parking.value}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                Yes
              </label>
            </div>
            <input
              type="text"
              name="parking.details"
              value={newProperty.parking.details}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter parking details..."
              disabled={!newProperty.parking.value}
            />
          </div>

          {/* Amenities */}
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">Amenities:</label>
            <div className="flex flex-wrap mb-2">
              {[
                'Fitness Center',
                'Rooftop Terrace',
                'Secure Entry',
                'Stainless Steel Appliances',
                'Granite Countertops',
                'Walk-in Closet',
                'In-unit Washer/Dryer',
                'High-Speed Internet Ready',
                'Central Air Conditioning',
              ].map((amenity) => (
                <label key={amenity} className="mr-4 mb-2 flex items-center">
                  <input
                    type="checkbox"
                    name="amenities"
                    value={amenity}
                    checked={newProperty.amenities.includes(amenity)}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  {amenity}
                </label>
              ))}
            </div>
            <div className="flex items-center mb-2">
              <input
                type="text"
                name="customAmenities"
                value={newProperty.customAmenities}
                onChange={handleInputChange}
                className="flex-grow px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="Add custom amenity..."
              />
              <button
                type="button"
                onClick={() => handleAddCustomItem('amenities', 'customAmenities')}
                className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
              >
                Add
              </button>
            </div>
          </div>

          {/* Restrictions */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold text-gray-700 mb-2">Restrictions:</h3>
            {Object.keys(newProperty.restrictions).map((key) => (
              <div key={key} className="mb-4 flex items-center">
                <label className="flex items-center mr-4">
                  <input
                    type="checkbox"
                    name={`restrictions.${key}.value`}
                    checked={newProperty.restrictions[key].value}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  {key.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
                </label>
                <input
                  type="text"
                  name={`restrictions.${key}.details`}
                  value={newProperty.restrictions[key].details}
                  onChange={handleInputChange}
                  className="flex-grow px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Enter details..."
                />
              </div>
            ))}
          </div>

          {/* Neighborhood */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold text-gray-700 mb-2">Neighborhood:</h3>
            {Object.keys(newProperty.neighborhood).map((key) => (
              <div key={key} className="mb-4 flex flex-wrap">
                <div className="w-full md:w-1/2 md:pr-2">
                  <label className="block text-gray-700 font-semibold mb-1">
                    {key.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())} Name:
                  </label>
                  <input
                    type="text"
                    name={`neighborhood.${key}.name`}
                    value={newProperty.neighborhood[key].name}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    placeholder="e.g., Downtown Cleveland Daycare"
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2 mt-4 md:mt-0">
                  <label className="block text-gray-700 font-semibold mb-1">
                    {key.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())} Distance (miles):
                  </label>
                  <input
                    type="number"
                    name={`neighborhood.${key}.distance_in_miles`}
                    value={newProperty.neighborhood[key].distance_in_miles}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    placeholder="e.g., 0.5"
                  />
                </div>
              </div>
            ))}
          </div>

          {/* Fees */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold text-gray-700 mb-2">Fees:</h3>
            {[
              { title: 'Mandatory Monthly Fees', category: 'mandatory monthly fees' },
              { title: 'Optional Monthly Fees', category: 'optional monthly fees' },
              { title: 'Optional Deposits', category: 'optional deposits' },
              { title: 'Mandatory One-Time Fees', category: 'mandatory one-time fees' },
            ].map((feeSection) => (
              <div key={feeSection.category} className="mb-4">
                <h4 className="font-semibold mb-2">{feeSection.title}:</h4>
                {Object.entries(newProperty[feeSection.category]).map(([name, amount]) => (
                  <div key={name} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        const newName = e.target.value;
                        handleRemoveFee(feeSection.category, name);
                        handleAddFee(feeSection.category, newName, amount);
                      }}
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 mr-2"
                      placeholder="Fee Name"
                    />
                    <input
                      type="number"
                      name={`${feeSection.category}.${name}`}
                      value={amount}
                      onChange={(e) => handleFeeChange(feeSection.category, name, e.target.value)}
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 mr-2"
                      placeholder="Amount ($)"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveFee(feeSection.category, name)}
                      className="text-red-500 hover:text-red-700 font-bold text-xl"
                      title="Remove Fee"
                    >
                      &minus;
                    </button>
                  </div>
                ))}
                <div className="flex items-center mb-2">
                  <input
                    type="text"
                    value={newProperty[`custom_${feeSection.category}`] || ''}
                    onChange={(e) =>
                      setNewProperty((prev) => ({
                        ...prev,
                        [`custom_${feeSection.category}`]: e.target.value,
                      }))
                    }
                    className="flex-grow px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    placeholder={`Add ${feeSection.title} Name...`}
                  />
                  <input
                    type="number"
                    value={newProperty[`custom_${feeSection.category}_amount`] || ''}
                    onChange={(e) =>
                      setNewProperty((prev) => ({
                        ...prev,
                        [`custom_${feeSection.category}_amount`]: e.target.value,
                      }))
                    }
                    className="w-1/3 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 ml-2"
                    placeholder="Amount ($)"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const feeName = newProperty[`custom_${feeSection.category}`];
                      const feeAmount = newProperty[`custom_${feeSection.category}_amount`];
                      handleAddFee(feeSection.category, feeName, feeAmount);
                      setNewProperty((prev) => ({
                        ...prev,
                        [`custom_${feeSection.category}`]: '',
                        [`custom_${feeSection.category}_amount`]: '',
                      }));
                    }}
                    className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                  >
                    Add
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Utilities */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold text-gray-700 mb-2">Utilities:</h3>
            <div className="mb-4">
              <h4 className="font-semibold mb-2">Utilities Included in Rent:</h4>
              <div className="flex flex-wrap">
                {newProperty.utilities.map((utility, index) => (
                  <label key={index} className="mr-4 mb-2 flex items-center">
                    <input
                      type="checkbox"
                      name="utilities.included"
                      data-index={index}
                      checked={utility.included}
                      onChange={handleInputChange}
                      className="mr-2"
                    />
                    {utility.name}
                  </label>
                ))}
              </div>
            </div>

            <div className="flex items-center mb-2">
              <input
                type="text"
                name="customUtilities"
                value={newProperty.customUtilities}
                onChange={handleInputChange}
                className="flex-grow px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="Add custom utility..."
              />
              <button
                type="button"
                onClick={() => handleAddCustomItem('utilities', 'customUtilities')}
                className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
              >
                Add
              </button>
            </div>
          </div>

          <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded shadow">
            Submit Property
          </button>
        </form>
      )}

      <div className="mt-5">
        {!selectedProperty || [selectedProperty].length === 0 ? (
          <p className="text-gray-600">No properties available</p>
        ) : (
          selectedProperty.map((property) => (
            <div key={property.Kindred_ID} id={`property-${property.Kindred_ID}`} className="border border-gray-200 rounded-lg mb-4 bg-white shadow">
              <div className="p-4 cursor-pointer" onClick={() => toggleExpansion(property.Kindred_ID)}>
                <h3 className="text-lg font-semibold text-gray-800">{property.address}</h3>
                <p className="text-gray-600">
                  <strong>Description:</strong> {property.description}
                </p>
                {/* Display Kindred_ID after Description and before Base Monthly Rent */}
                <p className="text-gray-600">
                  <strong>Kindred ID:</strong> {property.Kindred_ID}
                </p>
                <p className="text-gray-600">
                  <strong>Base Monthly Rent:</strong> ${property['base monthly rent']}
                </p>
              </div>

              {expandedPropertyId === property.Kindred_ID && (
                <div className="flex">
                  <div id='properties' className="p-4 w-3/6">
                    {editingProperty && editingProperty.Kindred_ID === property.Kindred_ID ? (
                      <form onSubmit={handleSubmitProperty} className="mb-6 p-6 bg-white border border-gray-200 rounded-lg shadow-lg">
                        {/* Feedback Message Inside the Edit Form */}
                        {feedbackMessage.message && (
                          <div
                            className={`mb-4 p-4 rounded ${
                              feedbackMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                            }`}
                          >
                            {feedbackMessage.message}
                          </div>
                        )}

                        <h2 className="text-xl font-semibold text-gray-700 mb-4">Edit Property</h2>

                        {/* Common Input Fields */}
                        {[
                          { label: 'Address', name: 'address', type: 'text', required: true },
                          { label: 'Description', name: 'description', type: 'textarea', required: true },
                          { label: 'Kindred ID', name: 'Kindred_ID', type: 'text', required: false, disabled: true },
                          { label: 'Date Available', name: 'date available', type: 'date', required: true },
                          { label: 'Base Monthly Rent ($)', name: 'base monthly rent', type: 'number', required: true },
                          { label: 'Square Feet', name: 'square feet', type: 'number', required: true },
                          { label: 'Rooms', name: 'rooms', type: 'number', required: true },
                          { label: 'Bathrooms', name: 'baths', type: 'number', required: true },
                        ].map((field) =>
                          field.type === 'textarea' ? (
                            <div className="mb-4" key={field.name}>
                              <label className="block text-gray-700 font-semibold mb-2">{field.label}:</label>
                              <textarea
                                name={field.name}
                                value={newProperty[field.name]}
                                onChange={handleInputChange}
                                required={field.required}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                rows="4"
                                disabled={field.disabled}
                              ></textarea>
                            </div>
                          ) : field.name === 'Kindred_ID' ? (
                            <div className="mb-4" key={field.name}>
                              <label className="block text-gray-700 font-semibold mb-2">{field.label}:</label>
                              <input
                                type={field.type}
                                name={field.name}
                                value={newProperty[field.name]}
                                onChange={handleInputChange}
                                required={field.required}
                                className={`w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 bg-gray-100 cursor-not-allowed`}
                                placeholder={`Auto-generated ${field.label.toLowerCase()}`}
                                disabled
                              />
                            </div>
                          ) : (
                            <div className="mb-4" key={field.name}>
                              <label className="block text-gray-700 font-semibold mb-2">{field.label}:</label>
                              <input
                                type={field.type}
                                name={field.name}
                                value={field.type === 'date' ? newProperty[field.name].split('T')[0] : newProperty[field.name]}
                                onChange={handleInputChange}
                                required={field.required}
                                className={`w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 ${
                                  field.name === 'Kindred_ID' ? 'bg-gray-100 cursor-not-allowed' : ''
                                }`}
                                placeholder={field.type === 'date' ? 'Select date' : `Enter ${field.label.toLowerCase()}`}
                                disabled={field.disabled}
                              />
                            </div>
                          )
                        )}

                        {/* Parking */}
                        <div className="mb-4">
                          <label className="block text-gray-700 font-semibold mb-2">Parking Available:</label>
                          <div className="flex items-center mb-2">
                            <label className="mr-4 flex items-center">
                              <input
                                type="checkbox"
                                name="parking.value"
                                checked={newProperty.parking.value}
                                onChange={handleInputChange}
                                className="mr-2"
                              />
                              Yes
                            </label>
                          </div>
                          <input
                            type="text"
                            name="parking.details"
                            value={newProperty.parking.details}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                            placeholder="Enter parking details..."
                            disabled={!newProperty.parking.value}
                          />
                        </div>

                        {/* Amenities */}
                        <div className="mb-4">
                          <label className="block text-gray-700 font-semibold mb-2">Amenities:</label>
                          <div className="flex flex-wrap mb-2">
                            {[
                              'Fitness Center',
                              'Rooftop Terrace',
                              'Secure Entry',
                              'Stainless Steel Appliances',
                              'Granite Countertops',
                              'Walk-in Closet',
                              'In-unit Washer/Dryer',
                              'High-Speed Internet Ready',
                              'Central Air Conditioning',
                            ].map((amenity) => (
                              <label key={amenity} className="mr-4 mb-2 flex items-center">
                                <input
                                  type="checkbox"
                                  name="amenities"
                                  value={amenity}
                                  checked={newProperty.amenities.includes(amenity)}
                                  onChange={handleInputChange}
                                  className="mr-2"
                                />
                                {amenity}
                              </label>
                            ))}
                          </div>
                          <div className="flex items-center mb-2">
                            <input
                              type="text"
                              name="customAmenities"
                              value={newProperty.customAmenities}
                              onChange={handleInputChange}
                              className="flex-grow px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                              placeholder="Add custom amenity..."
                            />
                            <button
                              type="button"
                              onClick={() => handleAddCustomItem('amenities', 'customAmenities')}
                              className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                            >
                              Add
                            </button>
                          </div>
                        </div>

                        {/* Restrictions */}
                        <div className="mb-4">
                          <h3 className="text-lg font-semibold text-gray-700 mb-2">Restrictions:</h3>
                          {Object.keys(newProperty.restrictions).map((key) => (
                            <div key={key} className="mb-4 flex items-center">
                              <label className="flex items-center mr-4">
                                <input
                                  type="checkbox"
                                  name={`restrictions.${key}.value`}
                                  checked={newProperty.restrictions[key].value}
                                  onChange={handleInputChange}
                                  className="mr-2"
                                />
                                {key.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
                              </label>
                              <input
                                type="text"
                                name={`restrictions.${key}.details`}
                                value={newProperty.restrictions[key].details}
                                onChange={handleInputChange}
                                className="flex-grow px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                placeholder="Enter details..."
                              />
                            </div>
                          ))}
                        </div>

                        {/* Neighborhood */}
                        <div className="mb-4">
                          <h3 className="text-lg font-semibold text-gray-700 mb-2">Neighborhood:</h3>
                          {Object.keys(newProperty.neighborhood).map((key) => (
                            <div key={key} className="mb-4 flex flex-wrap">
                              <div className="w-full md:w-1/2 md:pr-2">
                                <label className="block text-gray-700 font-semibold mb-1">
                                  {key.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())} Name:
                                </label>
                                <input
                                  type="text"
                                  name={`neighborhood.${key}.name`}
                                  value={newProperty.neighborhood[key].name}
                                  onChange={handleInputChange}
                                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                  placeholder="e.g., Downtown Cleveland Daycare"
                                />
                              </div>
                              <div className="w-full md:w-1/2 md:pl-2 mt-4 md:mt-0">
                                <label className="block text-gray-700 font-semibold mb-1">
                                  {key.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())} Distance (miles):
                                </label>
                                <input
                                  type="number"
                                  name={`neighborhood.${key}.distance_in_miles`}
                                  value={newProperty.neighborhood[key].distance_in_miles}
                                  onChange={handleInputChange}
                                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                  placeholder="e.g., 0.5"
                                />
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Fees */}
                        <div className="mb-4">
                          <h3 className="text-lg font-semibold text-gray-700 mb-2">Fees:</h3>
                          {[
                            { title: 'Mandatory Monthly Fees', category: 'mandatory monthly fees' },
                            { title: 'Optional Monthly Fees', category: 'optional monthly fees' },
                            { title: 'Optional Deposits', category: 'optional deposits' },
                            { title: 'Mandatory One-Time Fees', category: 'mandatory one-time fees' },
                          ].map((feeSection) => (
                            <div key={feeSection.category} className="mb-4">
                              <h4 className="font-semibold mb-2">{feeSection.title}:</h4>
                              {Object.entries(newProperty[feeSection.category]).map(([name, amount]) => (
                                <div key={name} className="flex items-center mb-2">
                                  <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => {
                                      const newName = e.target.value;
                                      handleRemoveFee(feeSection.category, name);
                                      handleAddFee(feeSection.category, newName, amount);
                                    }}
                                    className="w-1/2 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 mr-2"
                                    placeholder="Fee Name"
                                  />
                                  <input
                                    type="number"
                                    name={`${feeSection.category}.${name}`}
                                    value={amount}
                                    onChange={(e) => handleFeeChange(feeSection.category, name, e.target.value)}
                                    className="w-1/2 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 mr-2"
                                    placeholder="Amount ($)"
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveFee(feeSection.category, name)}
                                    className="text-red-500 hover:text-red-700 font-bold text-xl"
                                    title="Remove Fee"
                                  >
                                    &minus;
                                  </button>
                                </div>
                              ))}
                              <div className="flex items-center mb-2">
                                <input
                                  type="text"
                                  value={newProperty[`custom_${feeSection.category}`] || ''}
                                  onChange={(e) =>
                                    setNewProperty((prev) => ({
                                      ...prev,
                                      [`custom_${feeSection.category}`]: e.target.value,
                                    }))
                                  }
                                  className="flex-grow px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                  placeholder={`Add ${feeSection.title} Name...`}
                                />
                                <input
                                  type="number"
                                  value={newProperty[`custom_${feeSection.category}_amount`] || ''}
                                  onChange={(e) =>
                                    setNewProperty((prev) => ({
                                      ...prev,
                                      [`custom_${feeSection.category}_amount`]: e.target.value,
                                    }))
                                  }
                                  className="w-1/3 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 ml-2"
                                  placeholder="Amount ($)"
                                />
                                <button
                                  type="button"
                                  onClick={() => {
                                    const feeName = newProperty[`custom_${feeSection.category}`];
                                    const feeAmount = newProperty[`custom_${feeSection.category}_amount`];
                                    handleAddFee(feeSection.category, feeName, feeAmount);
                                    setNewProperty((prev) => ({
                                      ...prev,
                                      [`custom_${feeSection.category}`]: '',
                                      [`custom_${feeSection.category}_amount`]: '',
                                    }));
                                  }}
                                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Utilities */}
                        <div className="mb-4">
                          <h3 className="text-lg font-semibold text-gray-700 mb-2">Utilities:</h3>
                          <div className="mb-4">
                            <h4 className="font-semibold mb-2">Utilities Included in Rent:</h4>
                            <div className="flex flex-wrap">
                              {newProperty.utilities.map((utility, index) => (
                                <label key={index} className="mr-4 mb-2 flex items-center">
                                  <input
                                    type="checkbox"
                                    name="utilities.included"
                                    data-index={index}
                                    checked={utility.included}
                                    onChange={handleInputChange}
                                    className="mr-2"
                                  />
                                  {utility.name}
                                </label>
                              ))}
                            </div>
                          </div>

                          <div className="flex items-center mb-2">
                            <input
                              type="text"
                              name="customUtilities"
                              value={newProperty.customUtilities}
                              onChange={handleInputChange}
                              className="flex-grow px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                              placeholder="Add custom utility..."
                            />
                            <button
                              type="button"
                              onClick={() => handleAddCustomItem('utilities', 'customUtilities')}
                              className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                            >
                              Add
                            </button>
                          </div>
                        </div>

                        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded shadow">
                          Update Property
                        </button>
                      </form>
                    ) : (
                      <>
                        {/* Property Details */}
                        {[
                          { label: 'Square Feet', value: `${property['square feet']} sq ft` },
                          { label: 'Rooms', value: property.rooms },
                          { label: 'Bathrooms', value: property.baths },
                          { label: 'Date Available', value: new Date(property['date available']).toLocaleDateString() },
                          {
                            label: 'Parking Available',
                            value: property.parking?.value ? 'Yes' : 'No',
                            conditional: property.parking?.value && { label: 'Parking Details', value: property.parking.details },
                          },
                          { label: 'Amenities', value: property.amenities?.join(', ') || 'None' },
                        ].map((item, idx) => (
                          <p key={idx} className="text-gray-600">
                            <strong>{item.label}:</strong> {item.value}
                            {item.conditional && (
                              <>
                                <br />
                                <strong>{item.conditional.label}:</strong> {item.conditional.value}
                              </>
                            )}
                          </p>
                        ))}

                        {/* Restrictions */}
                        <div className="text-gray-600 mt-2">
                          <strong>Restrictions:</strong>
                          <ul className="list-disc list-inside">
                            {Object.entries(property.restrictions).map(([key, res]) => (
                              <li key={key}>
                                {key.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}: {res.details || 'No details provided'}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {/* Neighborhood */}
                        <div className="text-gray-600 mt-2">
                          <strong>Neighborhood:</strong>
                          <ul className="list-disc list-inside">
                            {Object.entries(property.neighborhood).map(([key, place]) => (
                              <li key={key}>
                                {key.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}: {place.name || 'N/A'} ({place.distance_in_miles || '0'} miles)
                              </li>
                            ))}
                          </ul>
                        </div>

                        {/* Fees */}
                        <div className="text-gray-600 mt-2">
                          <strong>Fees:</strong>
                          {[
                            { title: 'Mandatory Monthly Fees', category: 'mandatory monthly fees' },
                            { title: 'Optional Monthly Fees', category: 'optional monthly fees' },
                            { title: 'Optional Deposits', category: 'optional deposits' },
                            { title: 'Mandatory One-Time Fees', category: 'mandatory one-time fees' },
                          ].map((feeSection) => (
                            <div key={feeSection.category}>
                              <p>{feeSection.title}:</p>
                              <ul className="list-disc list-inside">
                                {Object.entries(property[feeSection.category] || {}).map(([fee, amount]) => (
                                  <li key={fee}>
                                    {fee}: ${amount || '0'}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>

                        {/* Utilities */}
                        <div className="text-gray-600 mt-2">
                          <strong>Utilities Included in Rent:</strong> {property["utilities included in rent"]?.join(', ') || 'None'}
                          <br />
                          <strong>Utilities Not Included in Rent:</strong> {property["utilities not included in rent"]?.join(', ') || 'None'}
                        </div>

                        {/* Edit Button */}
                        <button
                          type="button"
                          className="mt-4 bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded shadow"
                          onClick={() => handleEditClick('property', property.Kindred_ID)}
                        >
                          Edit
                        </button>
                      </>
                    )}
                  </div>
                  <div id='qualifying' className="p-4 w-2/6">
                    <h4 className="font-semibold text-lg text-gray-800">Tenant Qualifications</h4>
                    {editingQualifications && editingQualifications.id === property.Kindred_ID ? (
                      <form onSubmit={handleSubmitQualifications} className="mt-4">
                        {/* Monthly Rent Multiple for Security Deposit */}
                        <div className="mb-4">
                          <label className="block text-gray-700 font-semibold mb-2">Monthly Rent Multiple for Security Deposit:</label>
                          <input
                            type="number"
                            step="0.1"
                            name="monthlyRentMultiple"
                            value={editingQualifications.data['monthly rent multiple for security deposit']}
                            onChange={handleQualificationsChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                          />
                        </div>

                        {/* Qualifying Criteria */}
                        <div className="mb-4">
                          <h5 className="font-semibold text-gray-700 mb-2">Qualifying Criteria:</h5>
                          {/* Allow Prior Convictions */}
                          <div className="flex items-center mb-2">
                            <label className="flex items-center mr-4">
                              <input
                                type="checkbox"
                                name="allowPriorConvictions"
                                checked={editingQualifications.data['qualifying criteria']['allow prior convictions']}
                                onChange={handleQualificationsChange}
                                className="mr-2"
                              />
                              Allow Prior Convictions
                            </label>
                          </div>

                          {/* Income Multiple for Qualifying */}
                          <div className="mb-2">
                            <label className="block text-gray-700 font-semibold mb-1">Income Multiple for Qualifying:</label>
                            <input
                              type="number"
                              step="0.1"
                              name="incomeMultiple"
                              value={editingQualifications.data['qualifying criteria']['income multiple for qualifying']}
                              onChange={handleQualificationsChange}
                              required
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                            />
                          </div>

                          {/* Maximum Number of People in Household */}
                          <div className="mb-2">
                            <label className="block text-gray-700 font-semibold mb-1">Maximum Number of People in Household:</label>
                            <input
                              type="number"
                              name="maxHousehold"
                              value={editingQualifications.data['qualifying criteria']['maximum number of people in household']}
                              onChange={handleQualificationsChange}
                              required
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                            />
                          </div>

                          {/* Minimum Credit Score */}
                          <div className="mb-2">
                            <label className="block text-gray-700 font-semibold mb-1">Minimum Credit Score:</label>
                            <input
                              type="number"
                              name="minCreditScore"
                              value={editingQualifications.data['qualifying criteria']['minimum credit score']}
                              onChange={handleQualificationsChange}
                              required
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                            />
                          </div>
                        </div>

                        {/* Submit and Cancel Buttons */}
                        <div className="flex space-x-2">
                          <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={() => setEditingQualifications(null)}
                            className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    ) : (
                      tenantQualifications[property.Kindred_ID] ? (
                        <div>
                          <h5 className="font-semibold text-gray-700">Monthly Rent Multiple for Security Deposit</h5>
                          <p className="text-gray-600">
                            {tenantQualifications[property.Kindred_ID]['monthly rent multiple for security deposit']}x monthly rent
                          </p>

                          <h5 className="font-semibold text-gray-700 mt-4">Qualifying Criteria:</h5>
                          <ul className="list-disc list-inside text-gray-600">
                            <li>
                              <strong>Allow Prior Convictions:</strong>{' '}
                              {tenantQualifications[property.Kindred_ID]['qualifying criteria']['allow prior convictions'] ? 'Yes' : 'No'}
                            </li>
                            <li>
                              <strong>Income Multiple for Qualifying:</strong>{' '}
                              {tenantQualifications[property.Kindred_ID]['qualifying criteria']['income multiple for qualifying']}x income
                            </li>
                            <li>
                              <strong>Maximum Number of People in Household:</strong>{' '}
                              {tenantQualifications[property.Kindred_ID]['qualifying criteria']['maximum number of people in household']}
                            </li>
                            <li>
                              <strong>Minimum Credit Score:</strong> {tenantQualifications[property.Kindred_ID]['qualifying criteria']['minimum credit score']}
                            </li>
                          </ul>
                          <button
                            type="button"
                            className="mt-4 bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded shadow"
                            onClick={() => handleEditClick('qualifying', property.Kindred_ID)}
                          >
                            Edit
                          </button>
                        </div>
                      ) : (
                        <p className="text-gray-600">Loading tenant qualifications...</p>
                      )
                    )}
                  </div>
                  <div id='policy' className="p-4 w-2/6">
                    <h4 className="font-semibold text-lg text-gray-800 mt-4">Specific Policies</h4>
                    {editingPolicies && editingPolicies.id === property.Kindred_ID ? (
                      <form onSubmit={handleSubmitPolicies} className="mt-4">
                        {/* Policy Content */}
                        <div className="mb-4">
                          <label className="block text-gray-700 font-semibold mb-2">Policy Content:</label>
                          <textarea
                            name="content"
                            value={editingPolicies.data['content']}
                            onChange={handlePoliciesChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                            rows="4"
                          ></textarea>
                        </div>

                        {/* Submit and Cancel Buttons */}
                        <div className="flex space-x-2">
                          <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={() => setEditingPolicies(null)}
                            className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    ) : (
                      specificPolicies[property.Kindred_ID] ? (
                        <div>
                          <h5 className="font-semibold text-gray-700">Policy</h5>
                          <p className="text-gray-600">{specificPolicies[property.Kindred_ID]['content']}</p>
                          <button
                            type="button"
                            className="mt-4 bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded shadow"
                            onClick={() => handleEditClick('policy', property.Kindred_ID)}
                          >
                            Edit
                          </button>
                        </div>
                      ) : (
                        <p className="text-gray-600">Loading specific policies...</p>
                      )
                    )}
                  </div>

                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PropertyDetail;