// src/components/ProtectedRoute.tsx
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const ProtectedRoute: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn === null) {
    // Render a loading indicator or null while checking auth status
    return <div>Loading...</div>;
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/" replace />;
  // return <Outlet />
};

export default ProtectedRoute;
