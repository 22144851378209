// src/components/Settings.tsx
import React, { useState } from 'react';

const Settings: React.FC = () => {
  const [settings, setSettings] = useState({
    notifications: true,
    darkMode: false,
    autoUpdate: true,
  });

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({...settings, [e.target.name]: e.target.checked});
  };

  return (
    <div className="p-6 bg-appleGray min-h-screen">
      <div className="max-w-2xl bg-appleWhite p-6 rounded-lg shadow-apple mx-auto">
        <h2 className="text-3xl font-semibold text-appleDarkGray mb-6">Settings</h2>
        <form>
          <div className="flex items-center justify-between mb-4">
            <label className="text-lg text-appleDarkGray">Enable Notifications</label>
            <input 
              type="checkbox" 
              name="notifications" 
              checked={settings.notifications} 
              onChange={handleToggle}
              className="h-6 w-6 text-appleBlue focus:ring-0 border-appleLightGray rounded"
            />
          </div>
          <div className="flex items-center justify-between mb-4">
            <label className="text-lg text-appleDarkGray">Dark Mode</label>
            <input 
              type="checkbox" 
              name="darkMode" 
              checked={settings.darkMode} 
              onChange={handleToggle}
              className="h-6 w-6 text-appleBlue focus:ring-0 border-appleLightGray rounded"
            />
          </div>
          <div className="flex items-center justify-between mb-4">
            <label className="text-lg text-appleDarkGray">Auto Update</label>
            <input 
              type="checkbox" 
              name="autoUpdate" 
              checked={settings.autoUpdate} 
              onChange={handleToggle}
              className="h-6 w-6 text-appleBlue focus:ring-0 border-appleLightGray rounded"
            />
          </div>
          <button 
            type="submit" 
            className="mt-6 w-full bg-appleBlue text-appleWhite p-3 rounded-md font-medium hover:bg-blue-600 transition"
          >
            Save Settings
          </button>
        </form>
      </div>
    </div>
  );
};

export default Settings;
