// src/components/Properties.tsx
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import '../components/Listing.css'; // Import the Neumorphic styles from Listing.css
import Listing from '../components/Listing';
import PropertyDetail from '../components/PropertyDetail.jsx';
import RepairTicketDetail from '../components/RepairTicketDetails.jsx';

interface API {
  api: string;
}

const RepairTickets: React.FC<API> = ({api}) => {
  const { user } = useContext(AuthContext);
  const [repairTickets, setRepairTickets] = useState<string[]>([]);
  const [selectedRepairTicket, setSelectedRepairTicket] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const API = api;

  function handleSelectedRepairTicket(repairticket: string) {
    setSelectedRepairTicket(repairticket);
  }

  // Fetch properties from the API when the component mounts
  useEffect(() => {
    const fetchRepairTickets = async () => {
      setLoading(true);
      setError(null);
      if (user) {
        try {
          const response = await axios.get(`${API}/get_folders?client_id=${user.id}`);
          setRepairTickets(response.data);
          setLoading(false);
        } catch (err) {
          console.error('Error fetching clients:', err);
          setError('Failed to load clients.');
          setLoading(false);
        }
      }
      
    };

    fetchRepairTickets();
  }, []);

  return (
    <div className="responsive-flex">
      {/* Client List */}
      <Listing clients={repairTickets} loading={loading} error={error} selectedClient={selectedRepairTicket} handleSelectedClient={handleSelectedRepairTicket} />
      {/* Chat History */}
      {selectedRepairTicket && API && user ? (
        <RepairTicketDetail selectedRepairTicket={selectedRepairTicket} apiBaseUrl={API} client_id={user.id} />
      ) : '' } ;
      {/* Summary */}
      {/* <Summary selectedClient={selectedClient}/> */}
    </div>
  );
};

export default RepairTickets;
