// src/components/Register.tsx
import React, { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import { useSocket } from '../contexts/SocketContext';
import { AuthContext } from '../contexts/AuthContext';
import Listing from '../components/Listing_register';
import MouseReactiveButton from './Button';
import './Register.css';

export interface ClientDetail {
  client_id: string;
  folder_name?: string;
  email: string;
  phone?: string;
  leasing_email_from?: string;
  forward_to?: string;
  cc_to?: string[];
  openphone_id?: string;
  main_office_number?: string;
  starting_k_id?: number;
  leasing_email_other_from?: string;
  leasing_email_other_subject?: string;
  maintenance_email_from?: string;
  maintenance_email_subject?: string;
  custom_fields?: { [key: string]: string };
}

interface Clients {
  email: string;
  client_id: string;
  folder_name: string;
}

interface APIProps {
  api: string;
}

const Subscriptors: React.FC<APIProps> = ({ api }) => {
  const { user, setUser, loginUser } = useContext(AuthContext);
  const socket = useSocket();

  const [clients, setClients] = useState<Clients[]>([]);
  const [selectedClient, setSelectedClient] = useState<string | null>(user ? user.email : null);
  const [selectedClientDetail, setSelectedClientDetail] = useState<ClientDetail>({ client_id: '', folder_name: '', email: '' });
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [customFields, setCustomFields] = useState<Array<{ key: string; value: string }>>([]);

  // 소켓을 통해 클라이언트 목록 요청
  useEffect(() => {
    if (!api) {
      console.error('Socket server URL is not set');
      return;
    }
    if (!user) {
      console.error('User is not logged in');
      return;
    }

    const handleConnectionError = (err: any) => {
      setError('WebSocket connection error.');
      setLoading(false);
      console.error('Connection Error:', err);
    };

    socket.emit('get_clients', { client_id: user.id, isSubscription: true });
    
    socket.on('clients_response', (data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setClients(data.data);
      }
    });

    socket.on("alert_crawler", (data) => {
      if (data.error) {
        setError(data.error);
      } else {
        console.log(data);
        if (data.clientName){
          alert(`Crawling complete. ${data.clientName}`);
        }
        else {
          alert(`RAG complete.`);
        }
      }
      
    });
    socket.on('connect_error', handleConnectionError);
    return () => {
      socket.off('clients_response');
      socket.off('alert_crawler');
      socket.off('connect_error');
    };
  }, [api, user, socket]);

  // axios를 통한 클라이언트 상세 정보 요청 (필요에 따라 socket 방식으로 변경 가능)
  useEffect(() => {
    if (!selectedClient) return;

    const fetchClientDetail = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${api}/get_clientDetail?email=${selectedClient}`);
        if (response.data.client_detail) {
          setSelectedClientDetail(response.data.client_detail);
        }
      } catch (error) {
        console.error('Error fetching client details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchClientDetail();
  }, [selectedClient, api]);

  const handleSelectedItem = (email: string) => {
    setSelectedClient(email);
  };

  // clientDetail이 바뀔 때마다 폼 필드를 업데이트
  useEffect(() => {
    if (!selectedClientDetail) return;
    if (!user) return;
    
    setUser({
      id: selectedClientDetail.client_id,
      email: selectedClientDetail.email || '',
      name: selectedClientDetail.folder_name || '',
      picture: '',
    });
    
    if (selectedClientDetail.custom_fields) {
      const fields = Object.entries(selectedClientDetail.custom_fields).map(
        ([key, value]) => ({ key, value })
      );
      setCustomFields(fields);
    } else {
      setCustomFields([]);
    }
  }, [selectedClientDetail]);

  // 커스텀 필드 추가 핸들러
  const handleAddCustomField = () => {
    setCustomFields([...customFields, { key: '', value: '' }]);
  };

  // 커스텀 필드의 key 변경 핸들러
  const handleCustomFieldKeyChange = (index: number, key: string) => {
    const updatedFields = [...customFields];
    updatedFields[index].key = key;
    setCustomFields(updatedFields);
  };

  // 커스텀 필드의 value 변경 핸들러
  const handleCustomFieldValueChange = (index: number, value: string) => {
    const updatedFields = [...customFields];
    updatedFields[index].value = value;
    setCustomFields(updatedFields);
  };

  // 커스텀 필드 삭제 핸들러
  const handleCloseCustomField = (index: number) => {
    const updatedFields = customFields.filter((_, idx) => idx !== index);
    setCustomFields(updatedFields);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const payload: any = { ...selectedClientDetail };
    delete payload.custom_fields;
    customFields.forEach(field => {
      if (field.key) {
        payload[field.key] = field.value;
      }
    });
    try {
      const response = await axios.post(`${api}/update_client_detail`, payload);
      if (response.status === 200) {
        setSelectedClientDetail(payload);
        setClients((prevClients) =>
          prevClients.map((client) =>
            client.email === selectedClientDetail.email ? payload : client
          )
        );
        setSuccess('Client registered successfully!');
        setError(null);
      } else {
        setError(String(response.status) || 'Failed to register client.');
        setSuccess(null);
      }
    } catch (err: any) {
      console.error('Error registering client:', err);
      setError('Error registering client.');
      setSuccess(null);
    }
  };

  const handleDeleteDynamicField = async (fieldKey: string) => {
    // local state에서 해당 필드 제거
    const { [fieldKey]: removed, ...rest } = selectedClientDetail as any;
    try {
      // 업데이트된 payload(rest)를 사용해 DB에 저장 (업데이트 API 호출)
      const response = await axios.post(`${api}/update_client_detail`, rest);
      if (response.status === 200) {
        setSelectedClientDetail(rest as ClientDetail);
        setClients((prevClients) =>
          prevClients.map((client) =>
            client.email === selectedClientDetail.email ? rest.email : client
          )
        );
        setSuccess(`Field "${fieldKey}" deleted successfully!`);
        setError(null);
      } else {
        setError('Failed to delete field.');
        setSuccess(null);
      }
    } catch (err: any) {
      console.error('Error deleting dynamic field:', err);
      setError('Error deleting field from DB.');
      setSuccess(null);
    }
  };

  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    const isConfirmed = window.confirm(`Are you sure you want to delete the client with email ${selectedClientDetail.email}?`);
    if (!isConfirmed) {
      return;
    }
    try {
      const response = await axios.post(`${api}/delete_client`, {
        client_id: selectedClientDetail.client_id, 
        folder_name: selectedClientDetail.folder_name
      });
      if (response.status === 200) {
        setSuccess('Client deleted successfully!');
        setError(null);
        setSelectedClientDetail({ client_id: '', folder_name: '', email: '' });
        setClients((prevClients) =>
          prevClients.filter((client) => client.client_id !== selectedClientDetail.client_id)
        );
        setSelectedClient(loginUser ? loginUser.email : '')
      } else {
        setError('Failed to delete client.');
        setSuccess(null);
      }
    } catch (err: any) {
      console.error('Error deleting client:', err);
      setError('Error deleting client.');
      setSuccess(null);
    }
  };

  // 표준 필드 목록 (동적 필드는 이 목록에 포함되지 않은 키로 판단)
  const standardFields = [
    'client_id',
    'folder_name',
    'email',
    'phone',
    'leasing_email_from',
    'forward_to',
    'cc_to',
    'openphone_id',
    'main_office_number',
    'starting_k_id',
    'leasing_email_other_from',
    'leasing_email_other_subject',
    'maintenance_email_from',
    'maintenance_email_subject',
    'custom_fields'
  ];

  // 동적(custom) 필드만 추출하여 렌더링
  const dynamicFields = Object.keys(selectedClientDetail).filter(
    (key) => !standardFields.includes(key)
  );

  const sortedClientDetailKeys = clients
    .map((client) => ({ email: client.email, client_id: client.client_id , folder_name: client.folder_name }))
    .sort((a, b) => a.email.localeCompare(b.email));

  return (
    <div className="responsive-flex">
      {clients && (
        <Listing
          items={clients}
          title="Property Managers"
          error={error}
          selectedItem={selectedClient}
          handleSelectedItem={handleSelectedItem}
        />
      )}

      {/* form */}
      <div className="form-column">
        <form className="client-detail-form">
          <div className="form-group">
            <label htmlFor="client_id">Client ID</label>
            <input
              type="text"
              id="client_id"
              disabled
              value={selectedClientDetail.client_id || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  client_id: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="folderName">Folder Name</label>
            <input
              type="text"
              id="folderName"
              disabled
              value={selectedClientDetail.folder_name || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  folder_name: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              disabled
              value={selectedClientDetail.email || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              disabled
              value={selectedClientDetail.phone || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  phone: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="leasingEmailFrom">Leasing Email From</label>
            <input
              type="email"
              id="leasingEmailFrom"
              disabled
              value={selectedClientDetail.leasing_email_from || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  leasing_email_from: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="forwardTo">Forward To</label>
            <input
              type="email"
              id="forwardTo"
              disabled
              value={selectedClientDetail.forward_to || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  forward_to: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="ccTo">CC To</label>
            <input
              type="text"
              id="ccTo"
              disabled
              value={selectedClientDetail.cc_to || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  cc_to: e.target.value.split(','),
                })
              }
              placeholder="comma separated emails"
            />
          </div>
          <div className="form-group">
            <label htmlFor="openphoneId">Openphone ID</label>
            <input
              type="text"
              id="openphoneId"
              disabled
              value={selectedClientDetail.openphone_id || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  openphone_id: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="main_office_number">Main Office Number</label>
            <input
              type="text"
              id="main_office_number"
              disabled
              value={selectedClientDetail.main_office_number || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  main_office_number: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="starting_k_id">Starting K ID</label>
            <input
              type="number"
              id="starting_k_id"
              disabled
              value={selectedClientDetail.starting_k_id || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  starting_k_id: Number(e.target.value),
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="leasingEmailOtherFrom">Leasing Email Other From</label>
            <input
              type="email"
              id="leasingEmailOtherFrom"
              disabled
              value={selectedClientDetail.leasing_email_other_from || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  leasing_email_other_from: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="leasingEmailOtherSubject">Leasing Email Other Subject</label>
            <input
              type="text"
              id="leasingEmailOtherSubject"
              disabled
              value={selectedClientDetail.leasing_email_other_subject || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  leasing_email_other_subject: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="maintenanceEmailFrom">Maintenance Email From</label>
            <input
              type="email"
              id="maintenanceEmailFrom"
              disabled
              value={selectedClientDetail.maintenance_email_from || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  maintenance_email_from: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="maintenanceEmailSubject">Maintenance Email Subject</label>
            <input
              type="text"
              id="maintenanceEmailSubject"
              disabled
              value={selectedClientDetail.maintenance_email_subject || ''}
              onChange={(e) =>
                setSelectedClientDetail({
                  ...selectedClientDetail,
                  maintenance_email_subject: e.target.value,
                })
              }
            />
          </div>

          {dynamicFields.map((key) => (
            <div className="form-group dynamic-field-group" key={key}>
              <label htmlFor={key}>{key}</label>
              <div className="dynamic-field-container">
                <input
                  type="text"
                  id={key}
                  disabled
                  value={(selectedClientDetail as any)[key] || ''}
                  onChange={(e) =>
                    setSelectedClientDetail({
                      ...selectedClientDetail,
                      [key]: e.target.value,
                    })
                  }
                />
                <button
                  type="button"
                  className="remove-dynamic-field-button"
                  onClick={() => handleDeleteDynamicField(key)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}

          {/* {customFields.map((field, index) => (
            <div className="custom-field-group" key={index}>
              <div className="form-group">
                <label>Custom Key</label>
                <input
                  type="text"
                  value={field.key}
                  onChange={(e) => handleCustomFieldKeyChange(index, e.target.value)}
                  placeholder="Key"
                />
              </div>
              <div className="form-group">
                <label>Custom Value</label>
                <input
                  type="text"
                  value={field.value}
                  onChange={(e) => handleCustomFieldValueChange(index, e.target.value)}
                  placeholder="Value"
                />
              </div>
              <button
                type="button"
                className="remove-custom-field-button"
                onClick={() => handleCloseCustomField(index)}
              >
                Close
              </button>
            </div>
          ))} */}
        

          {/* <div className="form-group">
            <button type="button" className="add-custom-field-button" onClick={handleAddCustomField}>
              +
            </button>
          </div> */}
        </form>

        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        {/* <div className="form-group row">
          <button type="submit" className="submit-button" onClick={handleSubmit}>
            Save Changes
          </button>
          <button type="submit" className="delete-button" onClick={handleDelete}>
            Delete
          </button>
          <MouseReactiveButton selectedItem={selectedClientDetail.client_id} />
        </div>
         */}
      </div>

  </div>
  );
};

export default Subscriptors;
